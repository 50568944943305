import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Select, Button, Form, Pagination, Slider, InputNumber, Checkbox } from "antd";
import dayjs from "dayjs";
import { EyeOutlined } from "@ant-design/icons";

const { Option } = Select;
class ShopGridV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredProperties: [], // For storing filtered results
      filteredPropertiesList: [],
      count: 0,
      currentPage: 1,
      pageSize: 20, // Number of items per page
      propertyType: "",
      city: "",
      bedrooms: "",
      baths: "",
      squareFeetRange: [], // Initial range for square footage
      min_price: 0,
      max_price: 999999999,
      developer: "",
      status: "",
      expandedGroups: {}, // State for managing expanded groups
      proj_name: "",
      activeView: "grid",
      sortBy: "",
      include_undefine_prices: true,
    };
  }
  setActiveView = (view) => {
    this.setState({ activeView: view });
  };
  componentDidMount() {
    this.fetchProperties();
    this.fetchPropertiesList();
  }
  fetchPropertiesList = async (page = 1) => {
    const {
      propertyType,
      city,
      bedrooms,
      baths,
      squareFeetRange,
      developer,
      min_price,
      max_price,
      status,
      proj_name,
      sortBy,
      include_undefine_prices,
    } = this.state;

    const url = `https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/listings/all/group/properties/?page=${page}${
      propertyType ? `&property_type=${propertyType}` : ""
    }${city ? `&city=${city}` : ""}${bedrooms ? `&beds=${bedrooms}` : ""}${
      baths ? `&baths=${baths}` : ""
    }${
      squareFeetRange && squareFeetRange.length > 0
        ? `&min_sqft=${squareFeetRange[0]}&max_sqft=${squareFeetRange[1]}`
        : ""
    }${`&min_price=${min_price}&max_price=${max_price}`}${
      developer ? `&developer=${developer}` : ""
    }${proj_name ? `&proj_name=${proj_name}` : ""}${
      sortBy ? `&sortBy=${sortBy}` : ""
    }${status ? `&status=${status}` : ""}${
      include_undefine_prices
        ? `&include_undefine_prices=${include_undefine_prices}`
        : ""
    }`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.setState({
        filteredPropertiesList: data.results, // Set initial filtered data
        count: data.count,
        currentPage: page,
      });
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  fetchProperties = async (page = 1) => {
    const {
      propertyType,
      city,
      bedrooms,
      baths,
      squareFeetRange,
      developer,
      min_price,
      max_price,
      status,
      proj_name,
      sortBy,
      include_undefine_prices,
    } = this.state;

    const url = `https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/listings/all/properties/?page=${page}${
      propertyType ? `&property_type=${propertyType}` : ""
    }${city ? `&city=${city}` : ""}${bedrooms ? `&beds=${bedrooms}` : ""}${
      baths ? `&baths=${baths}` : ""
    }${
      squareFeetRange && squareFeetRange.length > 0
        ? `&min_sqft=${squareFeetRange[0]}&max_sqft=${squareFeetRange[1]}`
        : ""
    }${`&min_price=${min_price}&max_price=${max_price}`}${
      developer ? `&developer=${developer}` : ""
    }${proj_name ? `&proj_name=${proj_name}` : ""}${
      sortBy ? `&sortBy=${sortBy}` : ""
    }${status ? `&status=${status}` : ""}${
      include_undefine_prices
        ? `&include_undefine_prices=${include_undefine_prices}`
        : ""
    }`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.setState({
        filteredProperties: data.results, // Set initial filtered data
        count: data.count,
        currentPage: page,
      });
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  handlePageChange = (page) => {
    if (this.state.activeView === "grid") {
      this.fetchProperties(page); // Fetch properties in grid view
    } else {
      this.fetchPropertiesList(page); // Fetch properties in other view (e.g., list view)
    }
  };

  handleSubmit = async (values) => {
    // Update state with form values and fetch properties
    this.setState(
      {
        propertyType: values.propertyType || "",
        city: values.city || "",
        bedrooms: values.bedrooms || "",
        baths: values.baths || "",
        squareFeetRange: values.squareFeetRange || [],
        min_price: values.min_price || 0,
        max_price: values.max_price || 999999999,
        status: values.status || "",
        developer: values.developer || "",
        proj_name: values.proj_name || "",
        sortBy: values.sortBy || "",
        include_undefine_prices: values.include_undefine_prices || false,
        currentPage: 1,
      },
      () => {
        // Fetch properties based on the updated state
        if (this.state.activeView === "grid") {
          this.fetchProperties(); // Fetch properties in grid view
        } else {
          this.fetchPropertiesList(); // Fetch properties in other view (e.g., list view)
        }
      }
    );
  };

  toggleGroup = (index) => {
    this.setState((prevState) => ({
      expandedGroups: {
        ...prevState.expandedGroups,
        [index]: !prevState.expandedGroups[index],
      },
    }));
  };

  phaseMapping = {
    PRE: "PRE-CONSTRUCTION",
    CON: "CONSTRUCTION",
    COMP: "COMPLETED",
    REG: "REGISTERING",
    SOLD: "SOLD",
    SELL: "SELLING",
  };

  render() {
    const {
      filteredProperties,
      filteredPropertiesList,
      currentPage,
      count,
      pageSize,
      squareFeetRange,
      min_price,
      expandedGroups,
      max_price,
      activeView,
    } = this.state;

    return (
      <div>
        <div className="ltn__product-area ltn__product-gutter">
        <div className="container" style={{ maxWidth: "95%" }}>
          <div className="row">
            <div className="col-lg-12 mt-1">
                <div className="ltn__car-dealer-form-tab ">
                  <div className="tab-content bg-white box-shadow-1 position-relative pb-10">
                    <div className="tab-pane fade active show">
                      <div className="car-dealer-form-inner">
                      <Form
                        onFinish={this.handleSubmit}
                        className="ltn__car-dealer-form-box row"
                        initialValues={{
                          include_undefine_prices: true, // Sets initial checked state
                        }}
                      >
                        <Form.Item
                          name="propertyType"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="Property Type"
                            allowClear
                            showSearch
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="Apartment">Apartments</Option>
                            <Option value="Condo">Condos</Option>
                            <Option value="Townhouse">Townhouses</Option>
                            <Option value="House">Single Family Houses</Option>
                            <Option value="Penthouse">Penthouses</Option>
                            <Option value="all">Other</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item name="city" className="col-lg-3 col-md-6">
                          <Select
                            placeholder="City"
                            allowClear
                            showSearch
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="SURREY">SURREY</Option>
                            <Option value="LANGLEY">LANGLEY</Option>
                            <Option value="VANCOUVER">VANCOUVER</Option>
                            <Option value="NORTH VANCOUVER">
                              NORTH VANCOUVER
                            </Option>
                            <Option value="WEST VANCOUVER">
                              WEST VANCOUVER
                            </Option>
                            <Option value="BURNABY">BURNABY</Option>
                            <Option value="COQUITLAM">COQUITLAM</Option>
                            <Option value="RICHMOND">RICHMOND</Option>
                            <Option value="PORT COQUITLAM">
                              PORT COQUITLAM
                            </Option>
                            <Option value="NEW WESTMINSTER">
                              NEW WESTMINSTER
                            </Option>
                            <Option value="DELTA">DELTA</Option>
                            <Option value="ABBOTSFORD">ABBOTSFORD</Option>
                            <Option value="CHILLIWACK">CHILLIWACK</Option>
                            <Option value="MAPLE RIDGE">MAPLE RIDGE</Option>
                            <Option value="WHITE ROCK">WHITE ROCK</Option>
                            <Option value="MISSION">MISSION</Option>
                            <Option value="HOPE">HOPE</Option>
                            <Option value="PRINCE GEORGE">PRINCE GEORGE</Option>
                            <Option value="PORT MOODY">PORT MOODY</Option>
                            <Option value="TSAWWASSEN">TSAWWASSEN</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="bedrooms"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="No Of Bedrooms"
                            allowClear
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="0.0">0</Option>
                            <Option value="0.5">0+</Option>
                            <Option value="1.0">1</Option>
                            <Option value="1.5">1+</Option>
                            <Option value="2.0">2</Option>
                            <Option value="2.5">2+</Option>
                            <Option value="3.0">3</Option>
                            <Option value="3.5">3+</Option>
                            <Option value="4.0">4</Option>
                            <Option value="4.5">4+</Option>
                            <Option value="5.0">5</Option>
                            <Option value="5.5">5+</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="baths"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="No Of Baths"
                            allowClear
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="0.0">0</Option>
                            <Option value="0.5">0+</Option>
                            <Option value="1.0">1</Option>
                            <Option value="1.5">1+</Option>
                            <Option value="2.0">2</Option>
                            <Option value="2.5">2+</Option>
                            <Option value="3.0">3</Option>
                            <Option value="3.5">3+</Option>
                            <Option value="4.0">4</Option>
                            <Option value="4.5">4+</Option>
                            <Option value="5.0">5</Option>
                            <Option value="5.5">5+</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="developer"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="Developer"
                            allowClear
                            showSearch
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="WANSON GROUP">WANSON GROUP</Option>
                            <Option value="LANDA GLOBAL">LANDA GLOBAL</Option>
                            <Option value="ML EMPORIO PROPERTIES">
                              ML EMPORIO PROPERTIES
                            </Option>
                            <Option value="ZENTERRA">ZENTERRA</Option>
                            <Option value="PANAROMA WEST GROUP">
                              PANAROMA WEST GROUP
                            </Option>
                            <Option value="CENTURY GROUP">CENTURY GROUP</Option>
                            <Option value="THIND">THIND</Option>
                            <Option value="ADERA">ADERA</Option>
                            <Option value="ALLURE VENTURES INC.">
                              ALLURE VENTURES INC.
                            </Option>
                            <Option value="PARK RIDGE HOMES">
                              PARK RIDGE HOMES
                            </Option>
                            <Option value="FOUR SQUARE PROPERTIES">
                              FOUR SQUARE PROPERTIES
                            </Option>
                            <Option value="STREETSIDE DEVELOPMENTS">
                              STREETSIDE DEVELOPMENTS
                            </Option>
                            <Option value="HIVE DEVELOPMENT">
                              HIVE DEVELOPMENT
                            </Option>
                            <Option value="PORTE COMMUNITIES">
                              PORTE COMMUNITIES
                            </Option>
                            <Option value="VESTERRA PROPERTIES">
                              VESTERRA PROPERTIES
                            </Option>
                            <Option value="MARATHON HOMES">
                              MARATHON HOMES
                            </Option>
                            <Option value="AVANI INVESTMENT GROUP">
                              AVANI INVESTMENT GROUP
                            </Option>
                            <Option value="SILVER STAR ENTERPRISES">
                              SILVER STAR ENTERPRISES
                            </Option>
                            <Option value="MOSAIC HOMES">MOSAIC HOMES</Option>
                            <Option value="CONCORD PACIFIC">
                              CONCORD PACIFIC
                            </Option>
                            <Option value="MORTISE GROUP OF COMPANIES">
                              MORTISE GROUP OF COMPANIES
                            </Option>
                            <Option value="WHITETAIL HOMES">
                              WHITETAIL HOMES
                            </Option>
                            <Option value="JAYEN PROPERTIES">
                              JAYEN PROPERTIES
                            </Option>
                            <Option value="YOUNG EARTH">YOUNG EARTH</Option>
                            <Option value="APCON GROUP">APCON GROUP</Option>
                            <Option value="GENARIS PROPERTIES">
                              GENARIS PROPERTIES
                            </Option>
                            <Option value="AOYUAN GROUP">AOYUAN GROUP</Option>
                            <Option value="LIONESTATE CONSTRUCTION">
                              LIONESTATE CONSTRUCTION
                            </Option>
                            <Option value="MANSE GROUP DEVELOPMENTS LTD.">
                              MANSE GROUP DEVELOPMENTS LTD.
                            </Option>
                            <Option value="MERTIAGE PROPERTIES LTD.">
                              MERTIAGE PROPERTIES LTD.
                            </Option>
                            <Option value="GRAMERCY DEVELOPMENTS">
                              GRAMERCY DEVELOPMENTS
                            </Option>
                            <Option value="MAINLAND DEVELOPMENTS">
                              MAINLAND DEVELOPMENTS
                            </Option>
                            <Option value="ACEPOINT">ACEPOINT</Option>
                            <Option value="INSPIRA">INSPIRA</Option>
                            <Option value="SIVIA CONSTRUCTION">
                              SIVIA CONSTRUCTION
                            </Option>
                            <Option value="DARSHAN BUILDERS">
                              DARSHAN BUILDERS
                            </Option>
                            <Option value="ELEGANT DEVELOPMENT">
                              ELEGANT DEVELOPMENT
                            </Option>
                            <Option value="DAWSON + SAWYER">
                              DAWSON + SAWYER
                            </Option>
                            <Option value="EVEREST GROUP">EVEREST GROUP</Option>
                            <Option value="VANQUISH HOMES">
                              VANQUISH HOMES
                            </Option>
                            <Option value="BLUESKY PROPERTIES & BOSA PROPERTIES">
                              BLUESKY PROPERTIES & BOSA PROPERTIES
                            </Option>
                            <Option value="QUADRA HOMES">QUADRA HOMES</Option>
                            <Option value="CRESCENT CREEK HOMES">
                              CRESCENT CREEK HOMES
                            </Option>
                            <Option value="PCC HOMES">PCC HOMES</Option>
                            <Option value="ZENITH DEVELOPMENT">
                              ZENITH DEVELOPMENT
                            </Option>
                            <Option value="RED CONSTRUCTION">
                              RED CONSTRUCTION
                            </Option>
                            <Option value="CANADA WEST GROUP">
                              CANADA WEST GROUP
                            </Option>
                            <Option value="ANSU DEVELOPMENT">
                              ANSU DEVELOPMENT
                            </Option>
                            <Option value="EPTA DEVELOPMENT CORPORATION">
                              EPTA DEVELOPMENT CORPORATION
                            </Option>
                            <Option value="ALABASTER">ALABASTER</Option>
                            <Option value="BUCCI">BUCCI</Option>
                            <Option value="WESGROUP">WESGROUP</Option>
                            <Option value="GROSVENOR">GROSVENOR</Option>
                            <Option value="BRIVIA GROUP & HENSON DEVELOPMENTS">
                              BRIVIA GROUP & HENSON DEVELOPMENTS
                            </Option>
                            <Option value="WESTBANK & QUADREAL">
                              WESTBANK & QUADREAL
                            </Option>
                            <Option value="EPIX DEVELOPMENTS LTD. & CIRCADIAN DEVELOPMENTS LTD.">
                              EPIX DEVELOPMENTS LTD. & CIRCADIAN DEVELOPMENTS
                              LTD.
                            </Option>
                            <Option value="FIRST TRACK DEVELOPMENT">
                              FIRST TRACK DEVELOPMENT
                            </Option>
                            <Option value="CRESSEY">CRESSEY</Option>
                            <Option value="TERRA BLANKA & LOTUS LIVING GROUP">
                              TERRA BLANKA & LOTUS LIVING GROUP
                            </Option>
                            <Option value="CHARD DEVELOPMENT">
                              CHARD DEVELOPMENT
                            </Option>
                            <Option value="LISTRAOR">LISTRAOR</Option>
                            <Option value="AMACON">AMACON</Option>
                            <Option value="SOLTERRA GROUP OF COMPANIES">
                              SOLTERRA GROUP OF COMPANIES
                            </Option>
                            <Option value="ONNI GROUP OF COMPANIES">
                              ONNI GROUP OF COMPANIES
                            </Option>
                            <Option value="GEORGIA PACIFIC HOLDINGS CORP">
                              GEORGIA PACIFIC HOLDINGS CORP
                            </Option>
                            <Option value="ULMUS DEVELOPMENT">
                              ULMUS DEVELOPMENT
                            </Option>
                            <Option value="POLYGON HOMES">POLYGON HOMES</Option>
                            <Option value="MARCON">MARCON</Option>
                            <Option value="DIMEX GROUP">DIMEX GROUP</Option>
                            <Option value="KAVAL PROPERTIES">
                              KAVAL PROPERTIES
                            </Option>
                            <Option value="1969 EAST 49TH AVENUE LTD PARTNERSHIP">
                              1969 EAST 49TH AVENUE LTD PARTNERSHIP
                            </Option>
                            <Option value="VICINI HOMES & CONWEST">
                              VICINI HOMES & CONWEST
                            </Option>
                            <Option value="AMC DEVELOPMENT">
                              AMC DEVELOPMENT
                            </Option>
                            <Option value="AREE DEVELOPMENT">
                              AREE DEVELOPMENT
                            </Option>
                            <Option value="ASIA STANDARD AMERICAS">
                              ASIA STANDARD AMERICAS
                            </Option>
                            <Option value="IFORTUNE">IFORTUNE</Option>
                            <Option value="SOUTH STREET DEVELOPMENT GROUP">
                              SOUTH STREET DEVELOPMENT GROUP
                            </Option>
                            <Option value="ANEEK DEVELOPMENT">
                              ANEEK DEVELOPMENT
                            </Option>
                            <Option value="CONCERT">CONCERT</Option>
                            <Option value="BOVO DEVELOPMENT LTD.">
                              BOVO DEVELOPMENT LTD.
                            </Option>
                            <Option value="EVERBRIGHT PROPERTIES">
                              EVERBRIGHT PROPERTIES
                            </Option>
                            <Option value="MATCHPOINT DEVELOPMENT">
                              MATCHPOINT DEVELOPMENT
                            </Option>
                            <Option value="HAWTHORNE DEVELOPMENTS, MEDALLION HOMES INC. & EDGE CONSTRUCTION AND DESIGN">
                              HAWTHORNE DEVELOPMENTS, MEDALLION HOMES INC. &
                              EDGE CONSTRUCTION AND DESIGN
                            </Option>
                            <Option value="RENDITION DEVELOPMENTS">
                              RENDITION DEVELOPMENTS
                            </Option>
                            <Option value="BELFORD PROPERTIES">
                              BELFORD PROPERTIES
                            </Option>
                            <Option value="SHOKAI CANADA">SHOKAI CANADA</Option>
                            <Option value="PETERSON GROUP">
                              PETERSON GROUP
                            </Option>
                            <Option value="ANTHEM">ANTHEM</Option>
                            <Option value="ARDOR DEVELOPMENT">
                              ARDOR DEVELOPMENT
                            </Option>
                            <Option value="WESTBANK">WESTBANK</Option>
                            <Option value="FULLY HOMES">FULLY HOMES</Option>
                            <Option value="QUALEX-LANDMARK">
                              QUALEX-LANDMARK
                            </Option>
                            <Option value="LAVERN DEVELOPMENTS">
                              LAVERN DEVELOPMENTS
                            </Option>
                            <Option value="VERTEX DEVELOPMENTS">
                              VERTEX DEVELOPMENTS
                            </Option>
                            <Option value="SIGHTLINE PROPERTIES">
                              SIGHTLINE PROPERTIES
                            </Option>
                            <Option value="BOSA PROPERTIES & KINGSWOOD PROPERTIES">
                              BOSA PROPERTIES & KINGSWOOD PROPERTIES
                            </Option>
                            <Option value="RAR DEVELOPMENT">
                              RAR DEVELOPMENT
                            </Option>
                            <Option value="PINNACLE INTERNATIONAL">
                              PINNACLE INTERNATIONAL
                            </Option>
                            <Option value="HON DEVELOPMENTS">
                              HON DEVELOPMENTS
                            </Option>
                            <Option value="HANSEN PACIFIC">
                              HANSEN PACIFIC
                            </Option>
                            <Option value="HATHSTAUWK">HATHSTAUWK</Option>
                            <Option value="ARIA PACIFIC DEVELOPMENT INC.">
                              ARIA PACIFIC DEVELOPMENT INC.
                            </Option>
                            <Option value="VSP DEVELOPMENTS">
                              VSP DEVELOPMENTS
                            </Option>
                            <Option value="FASTMARK DEVELOPMENT CORP">
                              FASTMARK DEVELOPMENT CORP
                            </Option>
                            <Option value="MINGLIAN HOLDING LTD.">
                              MINGLIAN HOLDING LTD.
                            </Option>
                            <Option value="NOBLELAND DEVELOPMENTS">
                              NOBLELAND DEVELOPMENTS
                            </Option>
                            <Option value="EVERGLADE DEVELOPMENT">
                              EVERGLADE DEVELOPMENT
                            </Option>
                            <Option value="RIAA DEVELOPMENT">
                              RIAA DEVELOPMENT
                            </Option>
                            <Option value="BRENHILL">BRENHILL</Option>
                            <Option value="PENNYFARTHING HOMES">
                              PENNYFARTHING HOMES
                            </Option>
                            <Option value="GRYPHON DEVELOPMENT">
                              GRYPHON DEVELOPMENT
                            </Option>
                            <Option value="FORME DEVELOPMENT">
                              FORME DEVELOPMENT
                            </Option>
                            <Option value="WAVE DEVELOPMENTS LTD.">
                              WAVE DEVELOPMENTS LTD.
                            </Option>
                            <Option value="TOMO SPACES">TOMO SPACES</Option>
                            <Option value="KILLARNEY GROUP">
                              KILLARNEY GROUP
                            </Option>

                            <Option value="IMANI DEVELOPMENT">
                              IMANI DEVELOPMENT
                            </Option>

                            <Option value="HOLBORN">HOLBORN</Option>

                            <Option value="WESTLAND">WESTLAND</Option>

                            <Option value="TOWNLINE">TOWNLINE</Option>
                            <Option value="TRILLIUM PROJECTS">
                              TRILLIUM PROJECTS
                            </Option>
                            <Option value="CONCORD PACIFIC">
                              CONCORD PACIFIC
                            </Option>
                            <Option value="EPIX DEVELOPMENTS LTD.">
                              EPIX DEVELOPMENTS LTD.
                            </Option>
                            <Option value="MARCON">MARCON</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="proj_name"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="Project Name"
                            allowClear
                            showSearch
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="SILVA 3">SILVA 3</Option>
                            <Option value="LUCENT">LUCENT</Option>
                            <Option value="SEQUOIA">SEQUOIA</Option>
                            <Option value="KING + CRESCENT">
                              KING + CRESCENT
                            </Option>
                            <Option value="AMAYA LIVING">AMAYA LIVING</Option>
                            <Option value="CENTURY CITY HOLLAND PARK - PARK TOWER 1">
                              CENTURY CITY HOLLAND PARK - PARK TOWER 1
                            </Option>
                            <Option value="DISTRICT NORTHWEST - NORTH TOWER">
                              DISTRICT NORTHWEST - NORTH TOWER
                            </Option>
                            <Option value="PURA - PHASE 2">
                              PURA - PHASE 2
                            </Option>
                            <Option value="PURA - PHASE 1">
                              PURA - PHASE 1
                            </Option>
                            <Option value="THE GRAND ON KING GEORGE">
                              THE GRAND ON KING GEORGE
                            </Option>
                            <Option value="SOUTHAVEN">SOUTHAVEN</Option>
                            <Option value="VIVA">VIVA</Option>
                            <Option value="THE BOROUGHS (PHASE 2) - BEXLEY">
                              THE BOROUGHS (PHASE 2) - BEXLEY
                            </Option>
                            <Option value="ELISA AT SUNNYSIDE PARK">
                              ELISA AT SUNNYSIDE PARK
                            </Option>
                            <Option value="HARTLEY">HARTLEY</Option>
                            <Option value="JUNO">JUNO</Option>
                            <Option value="WESTWOOD AT TYNEHEAD">
                              WESTWOOD AT TYNEHEAD
                            </Option>
                            <Option value="KENDRICK">KENDRICK</Option>
                            <Option value="THE MAISONETTES">
                              THE MAISONETTES
                            </Option>
                            <Option value="OAK MEADOWS">OAK MEADOWS</Option>
                            <Option value="WOODWARD">WOODWARD</Option>
                            <Option value="PARK GEORGE">PARK GEORGE</Option>
                            <Option value="NOVA">NOVA</Option>
                            <Option value="GALILEA">GALILEA</Option>
                            <Option value="PARK & MAVEN">PARK & MAVEN</Option>
                            <Option value="DOUGLAS GREEN LIVING">
                              DOUGLAS GREEN LIVING
                            </Option>
                            <Option value="PARK & MAVEN (CARDINAL & HERON)">
                              PARK & MAVEN (CARDINAL & HERON)
                            </Option>
                            <Option value="72 CROSSROADS">72 CROSSROADS</Option>
                            <Option value="HARMONY">HARMONY</Option>
                            <Option value="BRIZA">BRIZA</Option>
                            <Option value="ONE CENTRAL">ONE CENTRAL</Option>
                            <Option value="THE 85">THE 85</Option>
                            <Option value="CENTRAL LIVING">
                              CENTRAL LIVING
                            </Option>
                            <Option value="THE PIANO">THE PIANO</Option>
                            <Option value="BELL POINTE">BELL POINTE</Option>
                            <Option value="THE LOOP">THE LOOP</Option>
                            <Option value="HEADWATER">HEADWATER</Option>
                            <Option value="COAST AT FRASER HEIGHTS">
                              COAST AT FRASER HEIGHTS
                            </Option>
                            <Option value="THE BEVERLY">THE BEVERLY</Option>
                            <Option value="INSIGNIA">INSIGNIA</Option>
                            <Option value="PRESTIGE">PRESTIGE</Option>
                            <Option value="PARKVILLE 96">PARKVILLE 96</Option>
                            <Option value="ELWYNN GREEN">ELWYNN GREEN</Option>
                            <Option value="GUILDFORD THE GREATEST">
                              GUILDFORD THE GREATEST
                            </Option>
                            <Option value="CENTRA">CENTRA</Option>
                            <Option value="OYNX & IVORY">OYNX & IVORY</Option>
                            <Option value="PARKWAY (PHASE 2)">
                              PARKWAY (PHASE 2)
                            </Option>
                            <Option value="PARK & MAVEN (TOWNHOMES)">
                              PARK & MAVEN (TOWNHOMES)
                            </Option>
                            <Option value="LEDGEVIEW (PHASE 2)">
                              LEDGEVIEW (PHASE 2)
                            </Option>
                            <Option value="JASPER">JASPER</Option>
                            <Option value="GLENMONT">GLENMONT</Option>
                            <Option value="CREST LANE">CREST LANE</Option>
                            <Option value="THE PARK LANDMARK">
                              THE PARK LANDMARK
                            </Option>
                            <Option value="FLEETWOOD VILLAGE 2 (TOWNHOMES)">
                              FLEETWOOD VILLAGE 2 (TOWNHOMES)
                            </Option>
                            <Option value="SOUTH VILLAGE">SOUTH VILLAGE</Option>
                            <Option value="SKYLIVING">SKYLIVING</Option>
                            <Option value="FLEETWOOD VILLAGE 2 (CONDOS) BUILDING 4 + 5">
                              FLEETWOOD VILLAGE 2 (CONDOS) BUILDING 4 + 5
                            </Option>
                            <Option value="FOX & AIKINS">FOX & AIKINS</Option>
                            <Option value="BOURNA">BOURNA</Option>
                            <Option value="CADENCE">CADENCE</Option>
                            <Option value="CREEKSIDE TERRACE">
                              CREEKSIDE TERRACE
                            </Option>
                            <Option value="PANORAMA WEST COAST LIVING">
                              PANORAMA WEST COAST LIVING
                            </Option>
                            <Option value="BAILEY">BAILEY</Option>
                            <Option value="MONUMENT">MONUMENT</Option>
                            <Option value="THESIS">THESIS</Option>
                            <Option value="2550 GARDEN DRIVE">
                              2550 GARDEN DRIVE
                            </Option>
                            <Option value="HARLIN">HARLIN</Option>
                            <Option value="1818 ALBERNI">1818 ALBERNI</Option>
                            <Option value="THE PACIFIC">THE PACIFIC</Option>
                            <Option value="CURV">CURV</Option>
                            <Option value="OAKRIDGE X WESTBANK">
                              OAKRIDGE X WESTBANK
                            </Option>
                            <Option value="POPOLO">POPOLO</Option>
                            <Option value="WOODLAND BLOCK">
                              WOODLAND BLOCK
                            </Option>
                            <Option value="FORMAT">FORMAT</Option>
                            <Option value="ASH l 28">ASH l 28</Option>
                            <Option value="EARL">EARL</Option>
                            <Option value="YORK">YORK</Option>
                            <Option value="THE PALAZZI AT PIERO LISSONI X OAKRIDGE">
                              THE PALAZZI AT PIERO LISSONI X OAKRIDGE
                            </Option>
                            <Option value="GRAFIA">GRAFIA</Option>
                            <Option value="THE GRANT">THE GRANT</Option>
                            <Option value="ITALIA">ITALIA</Option>
                            <Option value="1335 HOWE">1335 HOWE</Option>
                            <Option value="GEMINI MOUNT PLEASANT">
                              GEMINI MOUNT PLEASANT
                            </Option>
                            <Option value="OKU">OKU</Option>
                            <Option value="WORDSWORTH">WORDSWORTH</Option>
                            <Option value="MIRABEL">MIRABEL</Option>
                            <Option value="LAKEWOOD">LAKEWOOD</Option>
                            <Option value="RUPERT">RUPERT</Option>
                            <Option value="V ON E49">V ON E49</Option>
                            <Option value="GRACE">GRACE</Option>
                            <Option value="ROWEN W47">ROWEN W47</Option>
                            <Option value="TURNER'S DAIRY">
                              TURNER'S DAIRY
                            </Option>
                            <Option value="THE CONSERVATORY">
                              THE CONSERVATORY
                            </Option>
                            <Option value="GARDEN 11">GARDEN 11</Option>
                            <Option value="LANDMARK ON ROBSON - EAST TOWER">
                              LANDMARK ON ROBSON - EAST TOWER
                            </Option>
                            <Option value="CLARIDGE HOUSE">
                              CLARIDGE HOUSE
                            </Option>
                            <Option value="ELM41">ELM41</Option>
                            <Option value="ACE">ACE</Option>
                            <Option value="OAK KEYS">OAK KEYS</Option>
                            <Option value="DUNBAR AT 39TH">
                              DUNBAR AT 39TH
                            </Option>
                            <Option value="CAMBIE GARDENS - PHASE 1 (EAST TOWER)">
                              CAMBIE GARDENS - PHASE 1 (EAST TOWER)
                            </Option>
                            <Option value="HAMPTON COURT">HAMPTON COURT</Option>
                            <Option value="CAMBIE GARDENS - PHASE 1 (WEST TOWER)">
                              CAMBIE GARDENS - PHASE 1 (WEST TOWER)
                            </Option>
                            <Option value="TESORO">TESORO</Option>
                            <Option value="KNIGHT 18">KNIGHT 18</Option>
                            <Option value="LINA AT QE PARK">
                              LINA AT QE PARK
                            </Option>
                            <Option value="CHLOE">CHLOE</Option>
                            <Option value="AVALON 3">AVALON 3</Option>
                            <Option value="THE CHARLES">THE CHARLES</Option>
                            <Option value="SPARROW, CHINATOWN">
                              SPARROW, CHINATOWN
                            </Option>
                            <Option value="REVIVE (WELLSIDE COLLECTION)">
                              REVIVE (WELLSIDE COLLECTION)
                            </Option>
                            <Option value="WEST OAK">WEST OAK</Option>
                            <Option value="FRAME">FRAME</Option>
                            <Option value="FORM">FORM</Option>
                            <Option value="LAUREL32">LAUREL32</Option>
                            <Option value="BUTTERFLY">BUTTERFLY</Option>
                            <Option value="CLARENDON HEIGHTS">
                              CLARENDON HEIGHTS
                            </Option>
                            <Option value="LEGACY ON DUNBAR">
                              LEGACY ON DUNBAR
                            </Option>
                            <Option value="PURE">PURE</Option>
                            <Option value="THE HILLCREST">THE HILLCREST</Option>
                            <Option value="JUST WEST">JUST WEST</Option>
                            <Option value="SAXON">SAXON</Option>
                            <Option value="CADENCE">CADENCE</Option>
                            <Option value="SLOANE">SLOANE</Option>
                            <Option value="ENCORE 2">ENCORE 2</Option>
                            <Option value="ECLIPSE">ECLIPSE</Option>
                            <Option value="GRACE HIGHLANDS">
                              GRACE HIGHLANDS
                            </Option>
                            <Option value="TWILIGHT">TWILIGHT</Option>
                            <Option value="THE DELPHI">THE DELPHI</Option>
                            <Option value="RIVERVIEW">RIVERVIEW</Option>
                            <Option value="GLENDALE">GLENDALE</Option>
                            <Option value="THE SUMMIT">THE SUMMIT</Option>
                            <Option value="MERCY">MERCY</Option>
                            <Option value="MIRAGE">MIRAGE</Option>
                            <Option value="RADIUS">RADIUS</Option>
                            <Option value="WALLACE STREET">
                              WALLACE STREET
                            </Option>
                            <Option value="PROSPERITY">PROSPERITY</Option>
                            <Option value="BEACON 2">BEACON 2</Option>
                            <Option value="GRACE ON GRANDVIEW">
                              GRACE ON GRANDVIEW
                            </Option>
                            <Option value="BELCANTO">BELCANTO</Option>
                            <Option value="BRIGHTON VILLAGE">
                              BRIGHTON VILLAGE
                            </Option>
                            <Option value="WINDSOR TOWERS">
                              WINDSOR TOWERS
                            </Option>
                            <Option value="GROVE PLACE">GROVE PLACE</Option>
                            <Option value="FREMONT VILLAGE">
                              FREMONT VILLAGE
                            </Option>
                            <Option value="MEWS">MEWS</Option>
                            <Option value="PREPARED - PHASE 1">
                              PREPARED - PHASE 1
                            </Option>
                            <Option value="WINDSOR GREEN">WINDSOR GREEN</Option>
                            <Option value="CROSSROAD WEST">
                              CROSSROAD WEST
                            </Option>
                            <Option value="PRAIRIE VIEW">PRAIRIE VIEW</Option>
                            <Option value="THE MONTE CARLO">
                              THE MONTE CARLO
                            </Option>
                            <Option value="LUXE">LUXE</Option>
                            <Option value="GRACE - PHASE 1">
                              GRACE - PHASE 1
                            </Option>
                            <Option value="THE MAINSTAY">THE MAINSTAY</Option>
                            <Option value="GRAND EIGHT">GRAND EIGHT</Option>
                            <Option value="LITTLE BRITAIN">
                              LITTLE BRITAIN
                            </Option>
                            <Option value="BAIRD">BAIRD</Option>
                            <Option value="HORIZON AT VANCOUVER">
                              HORIZON AT VANCOUVER
                            </Option>
                            <Option value="DREW">DREW</Option>
                            <Option value="TAYLOR VILLAGE">
                              TAYLOR VILLAGE
                            </Option>
                            <Option value="SOVEREIGN">SOVEREIGN</Option>
                            <Option value="SYNERGY">SYNERGY</Option>
                            <Option value="REGENT GREEN">REGENT GREEN</Option>
                            <Option value="THE BRIDGEVIEW">
                              THE BRIDGEVIEW
                            </Option>
                            <Option value="THE MONTEREY">THE MONTEREY</Option>
                            <Option value="AVALON">AVALON</Option>
                            <Option value="BISCUITS">BISCUITS</Option>
                            <Option value="THE PHOENIX">THE PHOENIX</Option>
                            <Option value="ENCORE">ENCORE</Option>
                            <Option value="EVERETT">EVERETT</Option>
                            <Option value="THE REEF">THE REEF</Option>
                            <Option value="LUNA">LUNA</Option>
                            <Option value="THE PARK PLACE">
                              THE PARK PLACE
                            </Option>
                            <Option value="SOUNDWAVES">SOUNDWAVES</Option>
                            <Option value="THE CURVE">THE CURVE</Option>
                            <Option value="NEVE 1">NEVE 1</Option>
                            <Option value="ARTS & CULTURE">
                              ARTS & CULTURE
                            </Option>
                            <Option value="NEVE 2">NEVE 2</Option>
                            <Option value="TRILOGY">TRILOGY</Option>
                            <Option value="HEIRLOOM">HEIRLOOM</Option>
                            <Option value="NEVE 3">NEVE 3</Option>
                            <Option value="ENCORE 3">ENCORE 3</Option>
                            <Option value="THE MARQUEE">THE MARQUEE</Option>
                            <Option value="BLAIR AVE">BLAIR AVE</Option>
                            <Option value="PARKWALK 1">PARKWALK 1</Option>
                            <Option value="BLAIR 2">BLAIR 2</Option>
                            <Option value="WINDSOR SQUARE">
                              WINDSOR SQUARE
                            </Option>
                            <Option value="WINDSOR GROVE">WINDSOR GROVE</Option>
                            <Option value="PARKWALK 2">PARKWALK 2</Option>
                            <Option value="PARKWALK 3">PARKWALK 3</Option>
                            <Option value="PARKWALK 4">PARKWALK 4</Option>
                            <Option value="PARKWALK 5">PARKWALK 5</Option>
                            <Option value="PARKWALK 6">PARKWALK 6</Option>
                            <Option value="PARKWALK 7">PARKWALK 7</Option>
                            <Option value="PARKWALK 8">PARKWALK 8</Option>
                            <Option value="PARKWALK 9">PARKWALK 9</Option>
                            <Option value="PARKWALK 10">PARKWALK 10</Option>
                            <Option value="PARKWALK 11">PARKWALK 11</Option>
                            <Option value="PARKWALK 12">PARKWALK 12</Option>
                            <Option value="PARKWALK 13">PARKWALK 13</Option>
                            <Option value="PARKWALK 14">PARKWALK 14</Option>
                            <Option value="PARKWALK 15">PARKWALK 15</Option>
                            <Option value="PARKWALK 16">PARKWALK 16</Option>
                            <Option value="PARKWALK 17">PARKWALK 17</Option>
                            <Option value="PARKWALK 18">PARKWALK 18</Option>
                            <Option value="PARKWALK 19">PARKWALK 19</Option>
                            <Option value="PARKWALK 20">PARKWALK 20</Option>
                            <Option value="PARKWALK 21">PARKWALK 21</Option>
                            <Option value="PARKWALK 22">PARKWALK 22</Option>
                            <Option value="PARKWALK 23">PARKWALK 23</Option>
                            <Option value="PARKWALK 24">PARKWALK 24</Option>
                            <Option value="PARKWALK 25">PARKWALK 25</Option>
                            <Option value="PARKWALK 26">PARKWALK 26</Option>
                            <Option value="PARKWALK 27">PARKWALK 27</Option>
                            <Option value="PARKWALK 28">PARKWALK 28</Option>
                            <Option value="PARKWALK 29">PARKWALK 29</Option>
                            <Option value="PARKWALK 30">PARKWALK 30</Option>
                            <Option value="PARKWALK 31">PARKWALK 31</Option>
                            <Option value="PARKWALK 32">PARKWALK 32</Option>
                            <Option value="PARKWALK 33">PARKWALK 33</Option>
                            <Option value="PARKWALK 34">PARKWALK 34</Option>
                            <Option value="PARKWALK 35">PARKWALK 35</Option>
                            <Option value="PARKWALK 36">PARKWALK 36</Option>
                            <Option value="PARKWALK 37">PARKWALK 37</Option>
                            <Option value="PARKWALK 38">PARKWALK 38</Option>
                            <Option value="PARKWALK 39">PARKWALK 39</Option>
                            <Option value="PARKWALK 40">PARKWALK 40</Option>
                            <Option value="PARKWALK 41">PARKWALK 41</Option>
                            <Option value="PARKWALK 42">PARKWALK 42</Option>
                            <Option value="PARKWALK 43">PARKWALK 43</Option>
                            <Option value="PARKWALK 44">PARKWALK 44</Option>
                            <Option value="PARKWALK 45">PARKWALK 45</Option>
                            <Option value="PARKWALK 46">PARKWALK 46</Option>
                            <Option value="PARKWALK 47">PARKWALK 47</Option>
                            <Option value="PARKWALK 48">PARKWALK 48</Option>
                            <Option value="PARKWALK 49">PARKWALK 49</Option>
                            <Option value="PARKWALK 50">PARKWALK 50</Option>
                            <Option value="PARKWALK 51">PARKWALK 51</Option>
                            <Option value="PARKWALK 52">PARKWALK 52</Option>
                            <Option value="PARKWALK 53">PARKWALK 53</Option>
                            <Option value="PARKWALK 54">PARKWALK 54</Option>
                            <Option value="PARKWALK 55">PARKWALK 55</Option>
                            <Option value="PARKWALK 56">PARKWALK 56</Option>
                            <Option value="PARKWALK 57">PARKWALK 57</Option>
                            <Option value="PARKWALK 58">PARKWALK 58</Option>
                            <Option value="PARKWALK 59">PARKWALK 59</Option>
                            <Option value="PARKWALK 60">PARKWALK 60</Option>
                            <Option value="PARKWALK 61">PARKWALK 61</Option>
                            <Option value="PARKWALK 62">PARKWALK 62</Option>
                            <Option value="PARKWALK 63">PARKWALK 63</Option>
                            <Option value="PARKWALK 64">PARKWALK 64</Option>
                            <Option value="PARKWALK 65">PARKWALK 65</Option>
                            <Option value="PARKWALK 66">PARKWALK 66</Option>
                            <Option value="PARKWALK 67">PARKWALK 67</Option>
                            <Option value="PARKWALK 68">PARKWALK 68</Option>
                            <Option value="PARKWALK 69">PARKWALK 69</Option>
                            <Option value="PARKWALK 70">PARKWALK 70</Option>
                            <Option value="PARKWALK 71">PARKWALK 71</Option>
                            <Option value="PARKWALK 72">PARKWALK 72</Option>
                            <Option value="PARKWALK 73">PARKWALK 73</Option>
                            <Option value="PARKWALK 74">PARKWALK 74</Option>
                            <Option value="PARKWALK 75">PARKWALK 75</Option>
                            <Option value="PARKWALK 76">PARKWALK 76</Option>
                            <Option value="PARKWALK 77">PARKWALK 77</Option>
                            <Option value="PARKWALK 78">PARKWALK 78</Option>
                            <Option value="PARKWALK 79">PARKWALK 79</Option>
                            <Option value="PARKWALK 80">PARKWALK 80</Option>
                            <Option value="PARKWALK 81">PARKWALK 81</Option>
                            <Option value="PARKWALK 82">PARKWALK 82</Option>
                            <Option value="PARKWALK 83">PARKWALK 83</Option>
                            <Option value="PARKWALK 84">PARKWALK 84</Option>
                            <Option value="PARKWALK 85">PARKWALK 85</Option>
                            <Option value="PARKWALK 86">PARKWALK 86</Option>
                            <Option value="PARKWALK 87">PARKWALK 87</Option>
                            <Option value="PARKWALK 88">PARKWALK 88</Option>
                            <Option value="PARKWALK 89">PARKWALK 89</Option>
                            <Option value="PARKWALK 90">PARKWALK 90</Option>
                            <Option value="PARKWALK 91">PARKWALK 91</Option>
                            <Option value="PARKWALK 92">PARKWALK 92</Option>
                            <Option value="PARKWALK 93">PARKWALK 93</Option>
                            <Option value="PARKWALK 94">PARKWALK 94</Option>
                            <Option value="PARKWALK 95">PARKWALK 95</Option>
                            <Option value="PARKWALK 96">PARKWALK 96</Option>
                            <Option value="PARKWALK 97">PARKWALK 97</Option>
                            <Option value="PARKWALK 98">PARKWALK 98</Option>
                            <Option value="PARKWALK 99">PARKWALK 99</Option>
                            <Option value="PARKWALK 100">PARKWALK 100</Option>
                            <Option value="PARKWALK 101">PARKWALK 101</Option>
                            <Option value="PARKWALK 102">PARKWALK 102</Option>
                            <Option value="PARKWALK 103">PARKWALK 103</Option>
                            <Option value="PARKWALK 104">PARKWALK 104</Option>
                            <Option value="PARKWALK 105">PARKWALK 105</Option>
                            <Option value="PARKWALK 106">PARKWALK 106</Option>
                            <Option value="PARKWALK 107">PARKWALK 107</Option>
                            <Option value="PARKWALK 108">PARKWALK 108</Option>
                            <Option value="PARKWALK 109">PARKWALK 109</Option>
                            <Option value="PARKWALK 110">PARKWALK 110</Option>
                            <Option value="PARKWALK 111">PARKWALK 111</Option>
                            <Option value="PARKWALK 112">PARKWALK 112</Option>
                            <Option value="PARKWALK 113">PARKWALK 113</Option>
                            <Option value="PARKWALK 114">PARKWALK 114</Option>
                            <Option value="PARKWALK 115">PARKWALK 115</Option>
                            <Option value="PARKWALK 116">PARKWALK 116</Option>
                            <Option value="PARKWALK 117">PARKWALK 117</Option>
                            <Option value="PARKWALK 118">PARKWALK 118</Option>
                            <Option value="PARKWALK 119">PARKWALK 119</Option>
                            <Option value="PARKWALK 120">PARKWALK 120</Option>
                            <Option value="PARKWALK 121">PARKWALK 121</Option>
                            <Option value="PARKWALK 122">PARKWALK 122</Option>
                            <Option value="PARKWALK 123">PARKWALK 123</Option>
                            <Option value="PARKWALK 124">PARKWALK 124</Option>
                            <Option value="PARKWALK 125">PARKWALK 125</Option>
                            <Option value="PARKWALK 126">PARKWALK 126</Option>
                            <Option value="PARKWALK 127">PARKWALK 127</Option>
                            <Option value="PARKWALK 128">PARKWALK 128</Option>
                            <Option value="PARKWALK 129">PARKWALK 129</Option>
                            <Option value="PARKWALK 130">PARKWALK 130</Option>
                            <Option value="PARKWALK 131">PARKWALK 131</Option>
                            <Option value="PARKWALK 132">PARKWALK 132</Option>
                            <Option value="PARKWALK 133">PARKWALK 133</Option>
                            <Option value="PARKWALK 134">PARKWALK 134</Option>
                            <Option value="PARKWALK 135">PARKWALK 135</Option>
                            <Option value="PARKWALK 136">PARKWALK 136</Option>
                            <Option value="PARKWALK 137">PARKWALK 137</Option>
                            <Option value="PARKWALK 138">PARKWALK 138</Option>
                            <Option value="PARKWALK 139">PARKWALK 139</Option>
                            <Option value="PARKWALK 140">PARKWALK 140</Option>
                            <Option value="PARKWALK 141">PARKWALK 141</Option>
                            <Option value="PARKWALK 142">PARKWALK 142</Option>
                            <Option value="PARKWALK 143">PARKWALK 143</Option>
                            <Option value="PARKWALK 144">PARKWALK 144</Option>
                            <Option value="PARKWALK 145">PARKWALK 145</Option>
                            <Option value="PARKWALK 146">PARKWALK 146</Option>
                            <Option value="PARKWALK 147">PARKWALK 147</Option>
                            <Option value="PARKWALK 148">PARKWALK 148</Option>
                            <Option value="PARKWALK 149">PARKWALK 149</Option>
                            <Option value="PARKWALK 150">PARKWALK 150</Option>
                            <Option value="PARKWALK 151">PARKWALK 151</Option>
                            <Option value="PARKWALK 152">PARKWALK 152</Option>
                            <Option value="PARKWALK 153">PARKWALK 153</Option>
                            <Option value="PARKWALK 154">PARKWALK 154</Option>
                            <Option value="PARKWALK 155">PARKWALK 155</Option>
                            <Option value="PARKWALK 156">PARKWALK 156</Option>
                            <Option value="PARKWALK 157">PARKWALK 157</Option>
                            <Option value="PARKWALK 158">PARKWALK 158</Option>
                            <Option value="PARKWALK 159">PARKWALK 159</Option>
                            <Option value="PARKWALK 160">PARKWALK 160</Option>
                            <Option value="PARKWALK 161">PARKWALK 161</Option>
                            <Option value="PARKWALK 162">PARKWALK 162</Option>
                            <Option value="PARKWALK 163">PARKWALK 163</Option>
                            <Option value="PARKWALK 164">PARKWALK 164</Option>
                            <Option value="PARKWALK 165">PARKWALK 165</Option>
                            <Option value="PARKWALK 166">PARKWALK 166</Option>
                            <Option value="PARKWALK 167">PARKWALK 167</Option>
                            <Option value="PARKWALK 168">PARKWALK 168</Option>
                            <Option value="PARKWALK 169">PARKWALK 169</Option>
                            <Option value="PARKWALK 170">PARKWALK 170</Option>
                            <Option value="PARKWALK 171">PARKWALK 171</Option>
                            <Option value="PARKWALK 172">PARKWALK 172</Option>
                            <Option value="PARKWALK 173">PARKWALK 173</Option>
                            <Option value="PARKWALK 174">PARKWALK 174</Option>
                            <Option value="PARKWALK 175">PARKWALK 175</Option>
                            <Option value="PARKWALK 176">PARKWALK 176</Option>
                            <Option value="PARKWALK 177">PARKWALK 177</Option>
                            <Option value="PARKWALK 178">PARKWALK 178</Option>
                            <Option value="PARKWALK 179">PARKWALK 179</Option>
                            <Option value="PARKWALK 180">PARKWALK 180</Option>
                            <Option value="PARKWALK 181">PARKWALK 181</Option>
                            <Option value="PARKWALK 182">PARKWALK 182</Option>
                            <Option value="PARKWALK 183">PARKWALK 183</Option>
                            <Option value="PARKWALK 184">PARKWALK 184</Option>
                            <Option value="PARKWALK 185">PARKWALK 185</Option>
                            <Option value="PARKWALK 186">PARKWALK 186</Option>
                            <Option value="PARKWALK 187">PARKWALK 187</Option>
                            <Option value="PARKWALK 188">PARKWALK 188</Option>
                            <Option value="PARKWALK 189">PARKWALK 189</Option>
                            <Option value="PARKWALK 190">PARKWALK 190</Option>
                            <Option value="PARKWALK 191">PARKWALK 191</Option>
                            <Option value="PARKWALK 192">PARKWALK 192</Option>
                            <Option value="PARKWALK 193">PARKWALK 193</Option>
                            <Option value="PARKWALK 194">PARKWALK 194</Option>
                            <Option value="PARKWALK 195">PARKWALK 195</Option>
                            <Option value="PARKWALK 196">PARKWALK 196</Option>
                            <Option value="PARKWALK 197">PARKWALK 197</Option>
                            <Option value="PARKWALK 198">PARKWALK 198</Option>
                            <Option value="PARKWALK 199">PARKWALK 199</Option>
                            <Option value="PARKWALK 200">PARKWALK 200</Option>
                            <Option value="PARKWALK 201">PARKWALK 201</Option>
                            <Option value="PARKWALK 202">PARKWALK 202</Option>
                            <Option value="PARKWALK 203">PARKWALK 203</Option>
                            <Option value="PARKWALK 204">PARKWALK 204</Option>
                            <Option value="PARKWALK 205">PARKWALK 205</Option>
                            <Option value="PARKWALK 206">PARKWALK 206</Option>
                            <Option value="PARKWALK 207">PARKWALK 207</Option>
                            <Option value="PARKWALK 208">PARKWALK 208</Option>
                            <Option value="PARKWALK 209">PARKWALK 209</Option>
                            <Option value="PARKWALK 210">PARKWALK 210</Option>
                            <Option value="PARKWALK 211">PARKWALK 211</Option>
                            <Option value="PARKWALK 212">PARKWALK 212</Option>
                            <Option value="PARKWALK 213">PARKWALK 213</Option>
                            <Option value="PARKWALK 214">PARKWALK 214</Option>
                            <Option value="PARKWALK 215">PARKWALK 215</Option>
                            <Option value="PARKWALK 216">PARKWALK 216</Option>
                            <Option value="PARKWALK 217">PARKWALK 217</Option>
                            <Option value="PARKWALK 218">PARKWALK 218</Option>
                            <Option value="PARKWALK 219">PARKWALK 219</Option>
                            <Option value="PARKWALK 220">PARKWALK 220</Option>
                            <Option value="PARKWALK 221">PARKWALK 221</Option>
                            <Option value="PARKWALK 222">PARKWALK 222</Option>
                            <Option value="PARKWALK 223">PARKWALK 223</Option>
                            <Option value="PARKWALK 224">PARKWALK 224</Option>
                            <Option value="PARKWALK 225">PARKWALK 225</Option>
                            <Option value="PARKWALK 226">PARKWALK 226</Option>
                            <Option value="PARKWALK 227">PARKWALK 227</Option>
                            <Option value="PARKWALK 228">PARKWALK 228</Option>
                            <Option value="PARKWALK 229">PARKWALK 229</Option>
                            <Option value="PARKWALK 230">PARKWALK 230</Option>
                            <Option value="PARKWALK 231">PARKWALK 231</Option>
                            <Option value="PARKWALK 232">PARKWALK 232</Option>
                            <Option value="PARKWALK 233">PARKWALK 233</Option>
                            <Option value="PARKWALK 234">PARKWALK 234</Option>
                            <Option value="PARKWALK 235">PARKWALK 235</Option>
                            <Option value="PARKWALK 236">PARKWALK 236</Option>
                            <Option value="PARKWALK 237">PARKWALK 237</Option>
                            <Option value="PARKWALK 238">PARKWALK 238</Option>
                            <Option value="PARKWALK 239">PARKWALK 239</Option>
                            <Option value="PARKWALK 240">PARKWALK 240</Option>
                            <Option value="PARKWALK 241">PARKWALK 241</Option>
                            <Option value="PARKWALK 242">PARKWALK 242</Option>
                            <Option value="PARKWALK 243">PARKWALK 243</Option>
                            <Option value="PARKWALK 244">PARKWALK 244</Option>
                            <Option value="PARKWALK 245">PARKWALK 245</Option>
                            <Option value="PARKWALK 246">PARKWALK 246</Option>
                            <Option value="PARKWALK 247">PARKWALK 247</Option>
                            <Option value="PARKWALK 248">PARKWALK 248</Option>
                            <Option value="PARKWALK 249">PARKWALK 249</Option>
                            <Option value="PARKWALK 250">PARKWALK 250</Option>
                            <Option value="PARKWALK 251">PARKWALK 251</Option>
                            <Option value="PARKWALK 252">PARKWALK 252</Option>
                            <Option value="PARKWALK 253">PARKWALK 253</Option>
                            <Option value="PARKWALK 254">PARKWALK 254</Option>
                            <Option value="PARKWALK 255">PARKWALK 255</Option>
                            <Option value="PARKWALK 256">PARKWALK 256</Option>
                            <Option value="PARKWALK 257">PARKWALK 257</Option>
                            <Option value="PARKWALK 258">PARKWALK 258</Option>
                            <Option value="PARKWALK 259">PARKWALK 259</Option>
                            <Option value="PARKWALK 260">PARKWALK 260</Option>
                            <Option value="PARKWALK 261">PARKWALK 261</Option>
                            <Option value="PARKWALK 262">PARKWALK 262</Option>
                            <Option value="PARKWALK 263">PARKWALK 263</Option>
                            <Option value="PARKWALK 264">PARKWALK 264</Option>
                            <Option value="PARKWALK 265">PARKWALK 265</Option>
                            <Option value="PARKWALK 266">PARKWALK 266</Option>
                            <Option value="PARKWALK 267">PARKWALK 267</Option>
                            <Option value="PARKWALK 268">PARKWALK 268</Option>
                            <Option value="PARKWALK 269">PARKWALK 269</Option>
                            <Option value="PARKWALK 270">PARKWALK 270</Option>
                            <Option value="PARKWALK 271">PARKWALK 271</Option>

                            <Option value="WALNUT PARK">WALNUT PARK</Option>
                            <Option value="7449 200 STREET CONDOS">
                              7449 200 STREET CONDOS
                            </Option>
                            <Option value="MIRADA ESTATES">
                              MIRADA ESTATES
                            </Option>
                            <Option value="EMORY">EMORY</Option>
                            <Option value="EVERGREEN PHASE II">
                              EVERGREEN PHASE II
                            </Option>
                            <Option value="8393 200 STREET TOWNHOMES">
                              8393 200 STREET TOWNHOMES
                            </Option>
                            <Option value="20701 84 AVENUE STREET CONDOS">
                              20701 84 AVENUE STREET CONDOS
                            </Option>
                            <Option value="7119-7143 207 STREET TOWNHOMES">
                              7119-7143 207 STREET TOWNHOMES
                            </Option>
                            <Option value="JERICHO - PHASE 3">
                              JERICHO - PHASE 3
                            </Option>
                            <Option value="20320 80 AVENUE TOWNHOMES">
                              20320 80 AVENUE TOWNHOMES
                            </Option>
                            <Option value="6920 204 STREET TOWNHOMES">
                              6920 204 STREET TOWNHOMES
                            </Option>
                            <Option value="9148 GLOVER ROAD CONDOS">
                              9148 GLOVER ROAD CONDOS
                            </Option>
                            <Option value="20722 80 AVENUE CONDOS">
                              20722 80 AVENUE CONDOS
                            </Option>
                            <Option value="27257 & 27265 28A AVENUE CONDOS">
                              27257 & 27265 28A AVENUE CONDOS
                            </Option>
                            <Option value="6705 & 6731 WILLOWBROOK CONNECTOR TOWNHOMES">
                              6705 & 6731 WILLOWBROOK CONNECTOR TOWNHOMES
                            </Option>
                            <Option value="19886 76 AVENUE CONDOS">
                              19886 76 AVENUE CONDOS
                            </Option>
                            <Option value="21305 83 AVENUE TOWNHOMES">
                              21305 83 AVENUE TOWNHOMES
                            </Option>
                            <Option value="27309 FRASER HIGHWAY CONDOS">
                              27309 FRASER HIGHWAY CONDOS
                            </Option>
                            <Option value="3321 200TH STREET TOWNHOMES">
                              3321 200TH STREET TOWNHOMES
                            </Option>
                            <Option value="21456 80 AVENUE TOWNHOMES">
                              21456 80 AVENUE TOWNHOMES
                            </Option>
                            <Option value="7240, 7258 210TH STREET & 21031, 21081 72ND AVENUE TOWNHOMES">
                              7240, 7258 210TH STREET & 21031, 21081 72ND AVENUE
                              TOWNHOMES
                            </Option>
                            <Option value="7042 AND 7058 204 STREET TOWNHOMES">
                              7042 AND 7058 204 STREET TOWNHOMES
                            </Option>
                            <Option value="7700 BLOCK OF 202A STREET TOWNHOMES">
                              7700 BLOCK OF 202A STREET TOWNHOMES
                            </Option>
                            <Option value="21198 SMITH CRESCENT TOWNHOMES">
                              21198 SMITH CRESCENT TOWNHOMES
                            </Option>
                            <Option value="20700 BLOCK OF 80 AVENUE CONDOS">
                              20700 BLOCK OF 80 AVENUE CONDOS
                            </Option>
                            <Option value="3282 202 STREET TOWNHOMES">
                              3282 202 STREET TOWNHOMES
                            </Option>
                            <Option value="7858 202A STREET TOWNHOMES">
                              7858 202A STREET TOWNHOMES
                            </Option>
                            <Option value="19681 80 AVENUE TOWNHOMES">
                              19681 80 AVENUE TOWNHOMES
                            </Option>
                            <Option value="AEGEAN STONE">AEGEAN STONE</Option>
                            <Option value="7573 AND 7595 203B STREET CONDOS">
                              7573 AND 7595 203B STREET CONDOS
                            </Option>
                            <Option value="19984 76 AVENUE CONDOS">
                              19984 76 AVENUE CONDOS
                            </Option>
                            <Option value="20701 84TH AVENUE TOWNHOMES">
                              20701 84TH AVENUE TOWNHOMES
                            </Option>
                            <Option value="7734 197 STREET TOWNHOMES">
                              7734 197 STREET TOWNHOMES
                            </Option>
                            <Option value="7642 206 STREET CONDOS">
                              7642 206 STREET CONDOS
                            </Option>
                            <Option value="20615 67 AVENUE CONDOS">
                              20615 67 AVENUE CONDOS
                            </Option>
                            <Option value="20443 70 AVENUE CONDOS">
                              20443 70 AVENUE CONDOS
                            </Option>
                            <Option value="6815 204 STREET TOWNHOMES">
                              6815 204 STREET TOWNHOMES
                            </Option>
                            <Option value="8393 200 STREET CONDOS">
                              8393 200 STREET CONDOS
                            </Option>
                            <Option value="20100 BLOCK OF 84 AVENUE TOWNHOMES">
                              20100 BLOCK OF 84 AVENUE TOWNHOMES
                            </Option>
                            <Option value="20290 72 AVENUE CONDOS">
                              20290 72 AVENUE CONDOS
                            </Option>
                            <Option value="20145 84 AVENUE TOWNHOMES">
                              20145 84 AVENUE TOWNHOMES
                            </Option>
                            <Option value="20309, 20339, 20347 68TH AVENUE & 6842, 6868 202B STREET TOWNHOMES">
                              20309, 20339, 20347 68TH AVENUE & 6842, 6868 202B
                              STREET TOWNHOMES
                            </Option>
                            <Option value="ROWAN">ROWAN</Option>
                            <Option value="ZAIL WILLOUGHBY LANGLEY II">
                              ZAIL WILLOUGHBY LANGLEY II
                            </Option>
                            <Option value="20040 & 20056 40A AVENUE CONDOS">
                              20040 & 20056 40A AVENUE CONDOS
                            </Option>
                            <Option value="20390 72 AVENUE CONDOS">
                              20390 72 AVENUE CONDOS
                            </Option>
                            <Option value="6869 210 STREET TOWNHOMES">
                              6869 210 STREET TOWNHOMES
                            </Option>
                            <Option value="7434-7452 202A STREET TOWNHOMES">
                              7434-7452 202A STREET TOWNHOMES
                            </Option>
                            <Option value="20049 82 AVENUE CONDOS">
                              20049 82 AVENUE CONDOS
                            </Option>
                            <Option value="20390 72 AVENUE TOWNHOMES">
                              20390 72 AVENUE TOWNHOMES
                            </Option>
                            <Option value="7033 204 STREET CONDOS">
                              7033 204 STREET CONDOS
                            </Option>
                            <Option value="6898 204 STREET TOWNHOMES">
                              6898 204 STREET TOWNHOMES
                            </Option>
                            <Option value="7240 & 7258 210 STREET TOWNHOMES">
                              7240 & 7258 210 STREET TOWNHOMES
                            </Option>
                            <Option value="19942 78B AVENUE CONDOS">
                              19942 78B AVENUE CONDOS
                            </Option>
                            <Option value="20370 AND 20392 73A AVENUE CONDOS">
                              20370 AND 20392 73A AVENUE CONDOS
                            </Option>
                            <Option value="CARVOLTH 2">CARVOLTH 2</Option>
                            <Option value="20315 & 20323 78 AVENUE TOWNHOMES">
                              20315 & 20323 78 AVENUE TOWNHOMES
                            </Option>
                            <Option value="YORKSON SW TOWNHOMES">
                              YORKSON SW TOWNHOMES
                            </Option>
                            <Option value="7725 208 STREET TOWNHOMES">
                              7725 208 STREET TOWNHOMES
                            </Option>
                            <Option value="20441 68 AVENUE TOWNHOMES">
                              20441 68 AVENUE TOWNHOMES
                            </Option>
                            <Option value="7642 206 STREET TOWNHOMES">
                              7642 206 STREET TOWNHOMES
                            </Option>
                            <Option value="20069 80 AVENUE CONDOS">
                              20069 80 AVENUE CONDOS
                            </Option>
                            <Option value="20347 68 AVENUE TOWNHOMES">
                              20347 68 AVENUE TOWNHOMES
                            </Option>
                            <Option value="20876 76 AVENUE TOWNHOMES">
                              20876 76 AVENUE TOWNHOMES
                            </Option>
                            <Option value="CARVOLTH TOWNHOMES">
                              CARVOLTH TOWNHOMES
                            </Option>
                            <Option value="20645 74B AVENUE TOWNHOMES">
                              20645 74B AVENUE TOWNHOMES
                            </Option>
                            <Option value="2600 BLOCK OF 268TH STREET TOWNHOMES">
                              2600 BLOCK OF 268TH STREET TOWNHOMES
                            </Option>
                            <Option value="3134 200 STREET TOWNHOMES">
                              3134 200 STREET TOWNHOMES
                            </Option>
                            <Option value="JERICHO - PHASE 2">
                              JERICHO - PHASE 2
                            </Option>
                            <Option value="21200 BLOCK OF 83 AVENUE TOWNHOMES">
                              21200 BLOCK OF 83 AVENUE TOWNHOMES
                            </Option>
                            <Option value="7130 202B STREET CONDOS">
                              7130 202B STREET CONDOS
                            </Option>
                            <Option value="20145 84 AVENUE CONDOS">
                              20145 84 AVENUE CONDOS
                            </Option>
                            <Option value="CULMENA">CULMENA</Option>
                            <Option value="NAUTILUS">NAUTILUS</Option>
                            <Option value="RUSSEL AND MAPLE">
                              RUSSEL AND MAPLE
                            </Option>
                            <Option value="COURTYARD COMMONS">
                              COURTYARD COMMONS
                            </Option>
                            <Option value="BEACHWAY 1">BEACHWAY 1</Option>
                            <Option value="14990 NORTH BLUFF ROAD CONDOS">
                              14990 NORTH BLUFF ROAD CONDOS
                            </Option>
                            <Option value="MIRACLE">MIRACLE</Option>
                            <Option value="1453 STAYTE ROAD TOWNHOMES">
                              1453 STAYTE ROAD TOWNHOMES
                            </Option>
                            <Option value="15409 BUENA VISTA AVENUE CONDOS">
                              15409 BUENA VISTA AVENUE CONDOS
                            </Option>
                            <Option value="THE MADISON AT HUNTER ROAD">
                              THE MADISON AT HUNTER ROAD
                            </Option>
                            <Option value="BOARDWALK - SALT AND MEADOWS 1">
                              BOARDWALK - SALT AND MEADOWS 1
                            </Option>
                            <Option value="BOARDWALK - SALT AND MEADOWS 2">
                              BOARDWALK - SALT AND MEADOWS 2
                            </Option>
                            <Option value="SCOTT + 77">SCOTT + 77</Option>
                            <Option value="BRIDGE & ELLIOTT">
                              BRIDGE & ELLIOTT
                            </Option>
                            <Option value="4726 - 4752 60B STREET TOWNHOMES">
                              4726 - 4752 60B STREET TOWNHOMES
                            </Option>
                            <Option value="WESTHAMPTON AT HAMPTON COVE">
                              WESTHAMPTON AT HAMPTON COVE
                            </Option>
                            <Option value="8037 - 8087 120 STREET CONDOS">
                              8037 - 8087 120 STREET CONDOS
                            </Option>
                            <Option value="DELTA CENTRAL 2">
                              DELTA CENTRAL 2
                            </Option>
                            <Option value="DELTITA GARDENS">
                              DELTITA GARDENS
                            </Option>
                            <Option value="LADNER SQUARE">LADNER SQUARE</Option>
                            <Option value="6115 HIGHWAY 17A CONDOS">
                              6115 HIGHWAY 17A CONDOS
                            </Option>
                            <Option value="11199 84 AVENUE CONDOS">
                              11199 84 AVENUE CONDOS
                            </Option>
                            <Option value="THE SHELBY">THE SHELBY</Option>
                            <Option value="4876-4938 57 STREET TOWNHOMES">
                              4876-4938 57 STREET TOWNHOMES
                            </Option>
                            <Option value="CARMEL COLLECTION">
                              CARMEL COLLECTION
                            </Option>
                            <Option value="114 171 STREET TOWNHOMES">
                              114 171 STREET TOWNHOMES
                            </Option>
                            <Option value="IRONWOOD">IRONWOOD</Option>
                            <Option value="CHAPTER">CHAPTER</Option>
                            <Option value="700-704 DELESTRE AVENUE CONDOS">
                              700-704 DELESTRE AVENUE CONDOS
                            </Option>
                            <Option value="PARTINGTON CREEK">
                              PARTINGTON CREEK
                            </Option>
                            <Option value="THE HEIGHTS ON AUSTIN - EAST TOWER">
                              THE HEIGHTS ON AUSTIN - EAST TOWER
                            </Option>
                            <Option value="FORESTER AT BURKE MOUNTAIN">
                              FORESTER AT BURKE MOUNTAIN
                            </Option>
                            <Option value="THE ROBINSONS - PARKSIDE COLLECTION">
                              THE ROBINSONS - PARKSIDE COLLECTION
                            </Option>
                            <Option value="ASPEN BY ZAKO">ASPEN BY ZAKO</Option>
                            <Option value="HARPER PEAK">HARPER PEAK</Option>
                            <Option value="PORTHAVEN">PORTHAVEN</Option>
                            <Option value="SOLANA">SOLANA</Option>
                            <Option value="MARY ANNE'S PLACE">
                              MARY ANNE'S PLACE
                            </Option>
                            <Option value="THE MANHATTAN">THE MANHATTAN</Option>
                            <Option value="INLET DISTRICT">
                              INLET DISTRICT
                            </Option>
                            <Option value="THE HIVE AT WILLOUGHBY TOWN CENTRE - PHASE 1">
                              THE HIVE AT WILLOUGHBY TOWN CENTRE - PHASE 1
                            </Option>
                            <Option value="KWASEN VILLAGE">
                              KWASEN VILLAGE
                            </Option>
                            <Option value="HEARTWOOD">HEARTWOOD</Option>
                            <Option value="PINE AND GLEN (EAST TOWER)">
                              PINE AND GLEN (EAST TOWER)
                            </Option>
                            <Option value="SOCO ONE">SOCO ONE</Option>
                            <Option value="SOLO 4">SOLO 4</Option>
                            <Option value="FRANK">FRANK</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="status"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="Status"
                            allowClear
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="PRE">PRE-CONSTRUCTION</Option>
                            <Option value="CON">CONSTRUCTION</Option>
                            <Option value="COMP">COMPLETED</Option>
                            <Option value="REG">REGISTERING</Option>
                            <Option value="SOLD">SOLD</Option>
                            <Option value="SELL">SELLING</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="sortBy"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="Sort By "
                            allowClear
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="1">Min Price</Option>
                            <Option value="2">Max Price </Option>
                            <Option value="3">Min Square Feet </Option>
                            <Option value="4">Max Square Feet </Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="squareFeetRange"
                          label="Square Feet"
                          className="col-lg-3 col-md-6"
                        >
                          <Slider
                            range
                            defaultValue={[0, 9999]}
                            max={9999}
                            step={1}
                            onChange={(value) =>
                              this.setState({ squareFeetRange: value })
                            }
                            value={squareFeetRange}
                          />
                        </Form.Item>
                        <Form.Item className="col-lg-3 col-md-6">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Item
                              name="min_price"
                              noStyle
                              rules={[
                                {
                                  type: "number",
                                  min: 0,
                                  message: "Minimum price must be at least 0",
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="Minimun Price"
                                min={0}
                                max={999999999}
                                style={{ marginRight: "10px", width: "100%" }}
                                value={min_price ? min_price : 0}
                              />
                            </Form.Item>
                            <Form.Item
                              name="max_price"
                              noStyle
                              rules={[
                                {
                                  type: "number",
                                  max: 999999999,
                                  message:
                                    "Maximum price must be at most 999999999",
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="Max Price"
                                min={0}
                                max={999999999}
                                style={{ width: "100%" }}
                                value={max_price ? max_price : 999999999}
                              />
                            </Form.Item>
                          </div>
                        </Form.Item>

                        <Form.Item
                          name="include_undefine_prices"
                          valuePropName="checked"
                          className="col-lg-3 col-md-6"
                        >
                          <Checkbox style={{ width: "100%" }}>
                            Inlude N/A
                          </Checkbox>
                        </Form.Item>
                        <div className="button-group d-flex justify-content col-lg-3 col-md-6">
                          <Form.Item className="car-price-filter-range">
                            <Button
                              htmlType="submit"
                              className="btn-outline-primary text-uppercase pt-1 text-dark"
                            >
                              Search Listings
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ltn__product-area ltn__product-gutter mt-4">
          <div className="container mb-1" style={{ maxWidth: "95%" }}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="ltn__shop-options">
                    <ul className="justify-content-start">
                      <li>
                        <div className="ltn__grid-list-tab-menu ">
                          <div className="nav">
                            <a
                              className={
                                activeView === "grid" ? "active show" : ""
                              }
                              data-bs-toggle="tab"
                              href="#liton_product_grid"
                              onClick={() => this.setActiveView("grid")}
                            >
                              <i className="fas fa-th-large" />
                            </a>
                            <a
                              className={
                                activeView === "list" ? "active show" : ""
                              }
                              data-bs-toggle="tab"
                              href="#liton_product_list"
                              onClick={() => this.setActiveView("list")}
                            >
                              <i className="fas fa-list" />
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-5" style={{ maxWidth: "95%" }}>
            <div className="row">
              <div className="col-lg-12  mb-100">
                {activeView === "grid" ? (
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="liton_product_grid"
                    >
                      <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                        <div className="row">
                          {filteredProperties.length > 0 ? (
                            filteredProperties.map((property) => (
                              <div
                                key={property.id}
                                className="col-xl-3 col-sm-6 col-3"
                              >
                                <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                  <div className="product-img go-top">
                                    <Link
                                      to={`/product-details/${property.id}`}
                                    >
                                      <img
                                        src={"assets/img/product-3/1.jpg"}
                                        alt={property.name}
                                      />
                                    </Link>
                                  </div>
                                  <div className="product-info">
                                    <div className="product-badge">
                                      <ul>
                                        <li className="sale-badg">
                                          {" "}
                                          {property.property_type}
                                        </li>
                                      </ul>
                                    </div>
                                    <h2 className="product-title go-top">
                                      <Link
                                        to={`/product-details/${property.id}`}
                                      >
                                        {property.name}
                                      </Link>
                                    </h2>
                                    <div className="product-img-location go-top">
                                      <ul>
                                        <li>
                                          <Link
                                            to={`/product-details/${property.id}`}
                                          >
                                            <i className="flaticon-pin" />{" "}
                                            {property.address} , {property.city}
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                      <li>
                                        <span>{property.beds} </span>
                                        Bed
                                      </li>
                                      <li>
                                        <span>{property.baths} </span>
                                        Bath
                                      </li>
                                      <li>
                                        <span>{property.sq} </span>
                                        Square Ft
                                      </li>
                                    </ul>
                                    {/* <div className="product-hover-action">
                                    <ul>
                                      <li>
                                        <a
                                          href="#"
                                          title="Quick View"
                                          data-bs-toggle="modal"
                                          data-bs-target="#quick_view_modal"
                                        >
                                          <i className="flaticon-expand" />
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#"
                                          title="Wishlist"
                                          data-bs-toggle="modal"
                                          data-bs-target="#liton_wishlist_modal"
                                        >
                                          <i className="flaticon-heart-1" />
                                        </a>
                                      </li>
                                      <li className="go-top">
                                        <Link
                                          to="/product-details"
                                          title="Product Details"
                                        >
                                          <i className="flaticon-add" />
                                        </Link>
                                      </li>
                                    </ul>
                                  </div> */}
                                  </div>
                                  <div className="product-info-bottom">
                                    <div className="product-price">
                                      <span>
                                        {property.developer || "N/A"}
                                        <label></label>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="col-12">
                              <p>No properties available.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="table-responsive " style={{ background: "white" }}>
                <table
                  className="table "
                  style={{ background: "white" }}
                >
                  <thead
                    className="text-center"
                    style={{ background: "white" }}
                  >
                    <tr style={{ background: "white" }}>
                      <th>Action</th>
                      <th>Project</th>
                      <th>Price</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>SQFT</th>
                      <th>Beds</th>
                      <th>Baths</th>
                      <th>AvgPrice/SQ</th>
                      <th>Status</th>
                      <th>CompletionDate</th>
                      <th>Developer</th>
                      <th>PropertyType</th>
                  
                      <th>Units</th>
                      <th>Stories</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-secondary text-center"
                    style={{ background: "white" }}
                  >
                        {filteredPropertiesList.map((propertyGroup, index) => (
                <React.Fragment key={index}>
                {/* Display only the first property by default */}
                {propertyGroup.slice(0, 1).map((property) => (
                  <tr
                    key={property.id}
                    style={{ cursor: "pointer", background: "white" }}
                  >
                    <td>
                      {propertyGroup.length > 1 && (
                        <button
                          style={{
                            fontSize: "22px",
                            width: "30px", // Set a width
                            height: "30px", // Set the same height
                            borderRadius: "50%", // Make it round
                            backgroundColor: "#0c579b", // Optional: add background color
                            border: "none", // Optional: remove border
                            cursor: "pointer", // Change cursor to pointer
                            display: "flex", // Center the content
                            alignItems: "center", // Center vertically
                            justifyContent: "center", // Center horizontally
                            color: "white",
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row's onClick event
                            this.toggleGroup(index);
                          }}
                        >
                          {expandedGroups[index] ? "-" : "+"}
                        </button>
                      )}
                    </td>
                    <td className="text-center">
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        <img
                          src={"assets/img/product-3/1.jpg"}
                          alt={property.name}
                          style={{ width: "80px", borderRadius: "8px" }}
                        />{" "}
                        <br />
                        <u>{property.name} </u>
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {property.price === 0
                          ? "N/A"
                          : `$${property.price}`}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {property.address}{" "}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {property.city}{" "}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {property.sq === "0" ? "N/A" : property.sq}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {property.beds}{" "}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {property.baths}{" "}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {parseFloat(property.avg_sq_price).toFixed(2)}{" "}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {this.phaseMapping[property.phase] ||
                          property.phase}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/product-details/${property.id}`}>
                        {property.completion_date === "TBD"
                          ? "TBD"
                          : dayjs(property.completion_date).format(
                              "YYYY-MM-DD"
                            )}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {property.developer}{" "}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {property.property_type}{" "}
                      </Link>
                    </td>

                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {property.units}{" "}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {property.stories}{" "}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        <u className="text-success">
                          <EyeOutlined />
                        </u>
                      </Link>
                    </td>
                  </tr>
                ))}
                {/* Show additional properties if the group is expanded */}
                {expandedGroups[index] &&
                  propertyGroup.slice(1).map((property) => (
                    <tr
                      key={property.id}
                      style={{ cursor: "pointer", background: "white" }}
                    >
                      <td></td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          <img
                            src={"assets/img/product-3/1.jpg"}
                            alt={property.name}
                            style={{ width: "80px" }}
                          />{" "}
                          <br />
                          <u>{property.name} </u>
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {property.price === 0
                            ? "N/A"
                            : `$${property.price}`}
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {property.address}{" "}
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {property.city}{" "}
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {property.sq === "0" ? "N/A" : property.sq}
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {property.beds}{" "}
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {property.baths}{" "}
                        </Link>
                      </td>
                      <td>
                      {" "}
                      <Link to={`/product-details/${property.id}`}>
                        {parseFloat(property.avg_sq_price).toFixed(2)}{" "}
                      </Link>
                    </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {this.phaseMapping[property.phase] ||
                            property.phase}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/product-details/${property.id}`}>
                          {property.completion_date === "TBD"
                            ? "TBD"
                            : dayjs(property.completion_date).format(
                                "YYYY-MM-DD"
                              )}
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {property.developer}{" "}
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {property.property_type}{" "}
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {property.units}{" "}
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          {property.stories}{" "}
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product-details/${property.id}`}>
                          <u className="text-success">
                            <EyeOutlined />
                          </u>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="ltn__pagination-area text-center">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {/* Pagination controls */}
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={count}
                      onChange={this.handlePageChange}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShopGridV1;
