import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

class PortfolioDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="ltn__page-details-area ltn__portfolio-details-area mb-105">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <div className="ltn__page-details-inner ltn__portfolio-details-inner">
                            <div className="ltn__blog-img">
                                <img
                                    src={"https://i0.wp.com/fratechain.com/wp-content/uploads/2021/12/key-keyhole-lock-2114046.jpg?fit=1280%2C720&ssl=1"}
                                    alt="Image"/>
                            </div>
                            <p><h5>Comments:</h5>When visitors leave comments on the site we collect the data shown in
                                the comments form, and also the visitor’s IP address and browser user agent string to
                                help spam detection.
                            </p>
                            <p><h5>Media:</h5>If you upload images to the website, you should avoid uploading images
                                with embedded location data (EXIF GPS) included. Visitors to the website can download
                                and extract any location data from images on the website.
                            </p>
                            <p><h5>Cookies:</h5>If you leave a comment on our site you may opt-in to saving your name,
                                email address and website in cookies. These are for your convenience so that you do not
                                have to fill in your details again when you leave another comment. These cookies will
                                last for one year. <br/>

                                If you visit our login page, we will set a temporary cookie to determine if your browser
                                accepts cookies. This cookie contains no personal data and is discarded when you close
                                your browser.<br/>

                                When you log in, we will also set up several cookies to save your login information and
                                your screen display choices. Login cookies last for two days, and screen options cookies
                                last for a year. If you select “Remember Me”, your login will persist for two weeks. If
                                you log out of your account, the login cookies will be removed.<br/>

                                If you edit or publish an article, an additional cookie will be saved in your browser.
                                This cookie includes no personal data and simply indicates the post ID of the article
                                you just edited. It expires after 1 day.
                            </p>
                            <p><h5>Embedded content from other websites:</h5>Articles on this site may include embedded
                                content (e.g. videos, images, articles, etc.). Embedded content from other websites
                                behaves in the exact same way as if the visitor has visited the other website.
                                <br/>

                                These websites may collect data about you, use cookies, embed additional third-party
                                tracking, and monitor your interaction with that embedded content, including tracking
                                your interaction with the embedded content if you have an account and are logged in to
                                that website.
                            </p>
                            <p><h5>How long we retain your data:</h5>
                                If you leave a comment, the comment and its metadata are retained indefinitely. This is
                                so we can recognize and approve any follow-up comments automatically instead of holding
                                them in a moderation queue. <br/>

                                For users that register on our website (if any), we also store the personal information
                                they provide in their user profile. All users can see, edit, or delete their personal
                                information at any time (except they cannot change their username). Website
                                administrators can also see and edit that information.
                            </p>
                            <p><h5>What rights you have over your data: </h5>If you have an account on this site, or
                                have left comments, you can request to receive an exported file of the personal data we
                                hold about you, including any data you have provided to us. You can also request that we
                                erase any personal data we hold about you. This does not include any data we are obliged
                                to keep for administrative, legal, or security purposes.
                            </p>
                            <p><h5>Where we send your data: </h5>Visitor comments may be checked through an automated
                                spam detection service.
                            </p>


                        </div>
                    </div>
                    <div className="col-lg-2"></div>

                </div>
            </div>
        </div>
    }
}

export default PortfolioDetails