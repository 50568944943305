import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";
const Location = () => {
  const [propertyDetailsList, setPropertyDetailsList] = useState([]);
  const center = [49.15335, -122.78555]; // Center of the map between Langley City and New Westminster

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/listings/properties/top-locations/"
        );
        const data = await response.json();
        // Map API response to propertyDetailsList format
        const properties = data.map((property) => ({
          id: property.id,
          name: property.name,
          price: property.price,
          address: property.address,
          property_type: property.property_type,
          developer: property.developer,
          city: property.city,
          position: [property.latitude, property.longitude], // Convert to number
        }));
        setPropertyDetailsList(properties);
      } catch (error) {
        console.error("Error fetching property data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run once when the component mounts

  return (
    <div className="m-5">
      <MapContainer
        center={center}
        zoom={12}
        style={{ height: "600px", width: "100%" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {propertyDetailsList.map((property) => (
          <Marker key={property.id} position={property.position}>
            <Popup>
              <img
                src={"assets/img/product-3/1.jpg"}
                alt={property.name}
                style={{
                  width: "100%",
                  height: "50%",
                  marginTop: 15,
                  marginBottom: 10,
                  marginLeft: 1,
                }}
              />
              <div style={{ fontFamily: "Arial, sans-serif", fontSize: "12" }}>
                <p>
                  {property.name} ( {property.property_type})
                </p>
       
                <p>Developer: {property.developer}</p>
                <p>
                  Location: {property.address},{property.city}
                </p>
                <p>
                  View Details:{" "}
                  <Link to={`/product-details/${property.id}`}>Click Here</Link>
                </p>
                <p>
                  price: {property.price === 0 ? "N/A" : `$${property.price}`}
                </p>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default Location;
