import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import dayjs from "dayjs";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

class ShopDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      propertyDetails: {
        latitude: 0, // Initial value
        longitude: 0, // Initial value
      },
      mapLoaded: false,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    const url = `https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/listings/property/details/${id}/`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => this.setState({ propertyDetails: data }))
      .catch((error) => this.setState({ error }));
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the latitude or longitude has changed and is not zero
    const { latitude, longitude } = this.state.propertyDetails;
    if (
      (latitude !== prevState.propertyDetails.latitude && latitude !== 0) ||
      (longitude !== prevState.propertyDetails.longitude && longitude !== 0)
    ) {
      this.setState({ mapLoaded: true });
    }
  }
  render() {
    const { propertyDetails, mapLoaded } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";
    const position = [
      propertyDetails?.latitude || "49.1392858",
      propertyDetails?.longitude || "-122.8415711",
    ];

    console.log("position", position);

    return (
      <div className="ltn__shop-details-area pb-10 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-category">
                      <Link to="#">Featured</Link>
                    </li>
                    <li className="ltn__blog-category">
                      <Link className="bg-orange" to="#">
                        {propertyDetails?.property_type}
                      </Link>
                    </li>
                  </ul>
                </div>
                <h1>{propertyDetails?.name}</h1>
                <img
                  src={"/assets/img/product-3/1.jpg"}
                  style={{ width: "100%", height: "50%", marginRight: 8 }}
                />
                <br />
                <br />
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  {propertyDetails?.address} , {propertyDetails?.city}
                </label>
                <h4 className="title-2">Description</h4>
                <p>{propertyDetails?.description}</p>
                <p>
                  To the left is the modern kitchen with central island, leading
                  through to the unique breakfast family room which feature
                  glass walls and doors out onto the garden and access to the
                  separate utility room.
                </p>
                <h4 className="title-2">Property Detail</h4>
                <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                  <ul>
                    <li>
                      <label>Property ID:</label>{" "}
                      <span>{propertyDetails?.id}</span>
                    </li>
                    <li>
                      <label>Developer:</label>{" "}
                      <span>{propertyDetails?.developer}</span>
                    </li>
                    <li>
                      <label>Area: </label>{" "}
                      <span>{propertyDetails?.sq} sqft</span>
                    </li>
                    <li>
                      <label>Beds:</label> <span>{propertyDetails?.beds}</span>
                    </li>
                    <li>
                      <label>Baths:</label>{" "}
                      <span>{propertyDetails?.baths}</span>
                    </li>

                    <li>
                      <label>Units:</label>{" "}
                      <span>{propertyDetails?.units}</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <label>Stories:</label>{" "}
                      <span>{propertyDetails?.stories}</span>
                    </li>
                    <li>
                      <label>Website:</label>{" "}
                      <span>
                        <a
                          href={propertyDetails?.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {propertyDetails?.website}{" "}
                        </a>
                      </span>
                    </li>
                    <li>
                      <label>Price:</label>{" "}
                      <span>
                        {propertyDetails.price === 0
                          ? "N/A"
                          : `$${propertyDetails.price}`}
                      </span>
                    </li>
                    <li>
                      <label>Year built:</label>{" "}
                      <span>
                         {propertyDetails.completion_date === "TBD"
                                  ? "TBD"
                                  : dayjs(propertyDetails.completion_date).format(
                                      "YYYY-MM-DD"
                                    )}
                      </span>
                    </li>
                    <li>
                      <label>Property Status:</label> <span>For Sale</span>
                    </li>
                  </ul>
                </div>
                <h4 className="title-2">Facts and Features</h4>
                <div className="property-detail-feature-list clearfix mb-45">
                  <ul>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="flaticon-double-bed" />
                        <div>
                          <h6>Master Bed Room</h6>
                          <small>
                            {propertyDetails?.master_bed_sq_ft
                              ? propertyDetails?.master_bed_sq_ft
                              : "N/A"}{" "}
                            feet
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="flaticon-double-bed" />
                        <div>
                          <h6>Rooftop Terrace</h6>
                          <small>
                            {propertyDetails?.rooftop_terrace
                              ? propertyDetails?.rooftop_terrace
                              : "N/A"}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="flaticon-double-bed" />
                        <div>
                          <h6>Guest Suite</h6>
                          <small>
                            {propertyDetails?.guest_suite
                              ? propertyDetails?.guest_suite
                              : "N/A"}{" "}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="flaticon-double-bed" />
                        <div>
                          <h6>Dining Area</h6>
                          <small>
                            {propertyDetails?.dining_area
                              ? propertyDetails?.dining_area
                              : "N/A"}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="flaticon-double-bed" />
                        <div>
                          <h6>Social Lounge</h6>
                          <small>
                            {propertyDetails?.social_lounge
                              ? propertyDetails?.social_lounge
                              : "N/A"}{" "}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="flaticon-double-bed" />
                        <div>
                          <h6>Gym </h6>
                          <small>
                            {propertyDetails?.gym
                              ? propertyDetails?.gym
                              : "N/A"}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="flaticon-double-bed" />
                        <div>
                          <h6>Garden</h6>
                          <small>
                            {propertyDetails?.garden
                              ? propertyDetails?.garden
                              : "N/A"}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="flaticon-double-bed" />
                        <div>
                          <h6>Parking</h6>
                          <small>
                            {propertyDetails?.park
                              ? propertyDetails?.park
                              : "N/A"}
                          </small>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location</h4>
                <div className="property-details-google-map mb-60">
                  {mapLoaded ? (
                    <MapContainer
                      center={position}
                      zoom={13}
                      style={{ height: "400px", width: "100%" }}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                      />
                      <Marker position={position}>
                        <Popup>
                          <img
                            src={"assets/img/product-3/1.jpg"}
                            alt={propertyDetails.name}
                            style={{
                              width: "100%",
                              height: "50%",
                              marginTop: 15,
                              marginBottom: 10,
                              marginLeft: 1,
                            }}
                          />
                          <br />
                          Property: {propertyDetails.name}
                          <br />
                          price: $ {propertyDetails.price}
                          <br />
                          Type: {propertyDetails.property_type}
                          <br />
                          Developer: {propertyDetails.developer}
                          <br />
                          City: {propertyDetails.city}
                          <br />
                          Location: {propertyDetails.address}
                        </Popup>
                      </Marker>
                    </MapContainer>
                  ) : (
                    <div>Loading map...</div>
                  )}
                </div>
                <h4 className="title-2">Floor Plans</h4>
                {/* APARTMENTS PLAN AREA START */}
                <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                  <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
                    <div className="nav">
                      <a data-bs-toggle="tab" href="#liton_tab_3_1">
                        First Floor
                      </a>
                      <a
                        className="active show"
                        data-bs-toggle="tab"
                        href="#liton_tab_3_2"
                      >
                        Second Floor
                      </a>
                      <a data-bs-toggle="tab" href="#liton_tab_3_3">
                        Third Floor
                      </a>
                      <a data-bs-toggle="tab" href="#liton_tab_3_4">
                        Top Garden
                      </a>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane fade" id="liton_tab_3_1">
                      <div className="ltn__apartments-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="apartments-plan-img">
                              <img
                                src={publicUrl + "assets/img/others/10.png"}
                                alt="#"
                              />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                              <h2>First Floor</h2>
                              <p>
                                Enimad minim veniam quis nostrud exercitation
                                ullamco laboris. Lorem ipsum dolor sit amet cons
                                aetetur adipisicing elit sedo eiusmod
                                tempor.Incididunt labore et dolore magna aliqua.
                                sed ayd minim veniam.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="product-details-apartments-info-list  section-bg-1">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="apartments-info-list apartments-info-list-color mt-40---">
                                    <ul>
                                      <li>
                                        <label>Total Area</label>{" "}
                                        <span>
                                          {propertyDetails?.sq} Sq. Ft
                                        </span>
                                      </li>
                                      <li>
                                        <label>BBQ</label>{" "}
                                        <span>
                                          {propertyDetails?.bbq
                                            ? propertyDetails?.bbq
                                            : "N/A"}{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="apartments-info-list apartments-info-list-color mt-40---">
                                    <ul>
                                      <li>
                                        <label>Pets</label>{" "}
                                        <span>
                                          {propertyDetails?.pets
                                            ? propertyDetails?.pets
                                            : "N/A"}{" "}
                                        </span>
                                      </li>
                                      <li>
                                        <label>Fireplace</label>{" "}
                                        <span>
                                          {propertyDetails?.fireplace}{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="liton_tab_3_2"
                    >
                      <div className="ltn__product-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="apartments-plan-img">
                              <img
                                src={publicUrl + "assets/img/others/10.png"}
                                alt="#"
                              />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                              <h2>Second Floor</h2>
                              <p>
                                Enimad minim veniam quis nostrud exercitation
                                ullamco laboris. Lorem ipsum dolor sit amet cons
                                aetetur adipisicing elit sedo eiusmod
                                tempor.Incididunt labore et dolore magna aliqua.
                                sed ayd minim veniam.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="product-details-apartments-info-list  section-bg-1">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="apartments-info-list apartments-info-list-color mt-40---">
                                    <ul>
                                      <li>
                                        <label>Total Area</label>{" "}
                                        <span>
                                          {propertyDetails?.sq} Sq. Ft
                                        </span>
                                      </li>
                                      <li>
                                        <label>BBQ</label>{" "}
                                        <span>
                                          {propertyDetails?.bbq
                                            ? propertyDetails?.bbq
                                            : "N/A"}{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="apartments-info-list apartments-info-list-color mt-40---">
                                    <ul>
                                      <li>
                                        <label>Pets</label>{" "}
                                        <span>
                                          {propertyDetails?.pets
                                            ? propertyDetails?.pets
                                            : "N/A"}{" "}
                                        </span>
                                      </li>
                                      <li>
                                        <label>Fireplace</label>{" "}
                                        <span>
                                          {propertyDetails?.fireplace}{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="liton_tab_3_3">
                      <div className="ltn__product-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="apartments-plan-img">
                              <img
                                src={publicUrl + "assets/img/others/10.png"}
                                alt="#"
                              />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                              <h2>Third Floor</h2>
                              <p>
                                Enimad minim veniam quis nostrud exercitation
                                ullamco laboris. Lorem ipsum dolor sit amet cons
                                aetetur adipisicing elit sedo eiusmod
                                tempor.Incididunt labore et dolore magna aliqua.
                                sed ayd minim veniam.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="product-details-apartments-info-list  section-bg-1">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="apartments-info-list apartments-info-list-color mt-40---">
                                    <ul>
                                      <li>
                                        <label>Total Area</label>{" "}
                                        <span>
                                          {propertyDetails?.sq} Sq. Ft
                                        </span>
                                      </li>
                                      <li>
                                        <label>BBQ</label>{" "}
                                        <span>
                                          {propertyDetails?.bbq
                                            ? propertyDetails?.bbq
                                            : "N/A"}{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="apartments-info-list apartments-info-list-color mt-40---">
                                    <ul>
                                      <li>
                                        <label>Pets</label>{" "}
                                        <span>
                                          {propertyDetails?.pets
                                            ? propertyDetails?.pets
                                            : "N/A"}{" "}
                                        </span>
                                      </li>
                                      <li>
                                        <label>Fireplace</label>{" "}
                                        <span>
                                          {propertyDetails?.fireplace}{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="liton_tab_3_4">
                      <div className="ltn__product-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="apartments-plan-img">
                              <img
                                src={publicUrl + "assets/img/others/10.png"}
                                alt="#"
                              />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                              <h2>Top Garden</h2>
                              <p>
                                Enimad minim veniam quis nostrud exercitation
                                ullamco laboris. Lorem ipsum dolor sit amet cons
                                aetetur adipisicing elit sedo eiusmod
                                tempor.Incididunt labore et dolore magna aliqua.
                                sed ayd minim veniam.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="product-details-apartments-info-list  section-bg-1">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="apartments-info-list apartments-info-list-color mt-40---">
                                    <ul>
                                      <li>
                                        <label>Total Area</label>{" "}
                                        <span>
                                          {propertyDetails?.sq} Sq. Ft
                                        </span>
                                      </li>
                                      <li>
                                        <label>BBQ</label>{" "}
                                        <span>
                                          {propertyDetails?.bbq
                                            ? propertyDetails?.bbq
                                            : "N/A"}{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="apartments-info-list apartments-info-list-color mt-40---">
                                    <ul>
                                      <li>
                                        <label>Pets</label>{" "}
                                        <span>
                                          {propertyDetails?.pets
                                            ? propertyDetails?.pets
                                            : "N/A"}{" "}
                                        </span>
                                      </li>
                                      <li>
                                        <label>Fireplace</label>{" "}
                                        <span>
                                          {propertyDetails?.fireplace}{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShopDetails;
