import React, { useEffect, useState } from 'react';
import axios from 'axios';

const StatsCounter = () => {
  // Set up state for the four stats
  const [stats, setStats] = useState({
    totalAreaSq: 0,
    apartmentsSold: 0,
    totalConstructions: 0,
    apartioRooms: 0,
  });

  // Fetch the data from the API
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/listings/api/real-estate-summary/');

        console.log('Total Count values are:',response.data);

        // Map the API data to each counter div
        setStats({
          totalAreaSq: Math.round(response.data.total_sq_ft) || 0,
          apartmentsSold: Math.round(response.data.total_units) || 0,
          totalConstructions: Math.round(response.data.total_projects) || 0,
          apartioRooms: Math.round(response.data.total_bedrooms)|| 0,
        });
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="row">
          <div className="col-md-3 col-sm-6 align-self-center">
        <div className="ltn__counterup-item text-color-white---">
          <div className="counter-icon">
            <i className="flaticon-excavator" />
          </div>
          <h1><span className="counter">{stats.totalConstructions}</span><span className="counterUp-icon">+</span> </h1>
          <h6>Total Projects</h6>
        </div>
      </div>
         <div className="col-md-3 col-sm-6 align-self-center">
        <div className="ltn__counterup-item text-color-white---">
          <div className="counter-icon">
            <i className="flaticon-office" />
          </div>
          <h1><span className="counter">{stats.apartmentsSold}</span><span className="counterUp-icon">+</span> </h1>
          <h6>Total Units</h6>
        </div>
      </div>

      <div className="col-md-3 col-sm-6 align-self-center">
        <div className="ltn__counterup-item text-color-white---">
          <div className="counter-icon">
            <i className="flaticon-select" />
          </div>
          <h1><span className="counter">{stats.totalAreaSq}</span><span className="counterUp-icon">+</span> </h1>
          <h6>Total Area (Sq)</h6>
        </div>
      </div>





      <div className="col-md-3 col-sm-6 align-self-center">
        <div className="ltn__counterup-item text-color-white---">
          <div className="counter-icon">
            <i className="flaticon-armchair" />
          </div>
          <h1><span className="counter">{stats.apartioRooms}</span><span className="counterUp-icon">+</span> </h1>
          <h6>Total Bedrooms</h6>
        </div>
      </div>
    </div>
  );
};

export default StatsCounter;
