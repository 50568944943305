import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';

class Navbar extends Component {
	
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
		<div>
           <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
			<div className="ltn__header-top-area section-bg-6 top-area-color-white---">
				<div className="container">
				<div className="row">
					<div className="col-md-7">
					<div className="ltn__top-bar-menu">
						<ul>
						<li><a href="mailto:info@moovmynt.com"><i className="icon-mail" />info@moovmynt.com</a></li>
						<li><a href="locations.html"><i className="icon-placeholder" />
200 – 4170 Still Creek Drive, Burnaby, BC</a></li>
						</ul>
					</div>
					</div>
					<div className="col-md-5">
					<div className="top-bar-right text-end">
						<div className="ltn__top-bar-menu">
						<ul>
							<li className="d-none">
							{/* ltn__language-menu */}
							<div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
								<ul>
								<li><a href="#" className="dropdown-toggle"><span className="active-currency">English</span></a>
									<ul>
									<li><Link to="#">Arabic</Link></li>
									<li><Link to="#">Bengali</Link></li>
									<li><Link to="#">Chinese</Link></li>
									<li><Link to="#">English</Link></li>
									<li><Link to="#">French</Link></li>
									<li><Link to="#">Hindi</Link></li>
									</ul>
								</li>
								</ul>
							</div>
							</li>
							<li>
								<Social />
							</li>
							<li>
							{/* header-top-btn */}

							</li>
						</ul>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
				<div className="container">
				<div className="row">
					<div className="col">
					<div className="site-logo-wrap">
						<div className="site-logo go-top">
						<Link to="/"><img src={publicUrl+"assets/img/Moovmynt - Revised Large Logo - White Background.png"}   alt="Logo" /></Link>
						</div>
						<div className="get-support clearfix d-none">
						<div className="get-support-icon">
							<i className="icon-call" />
						</div>
						<div className="get-support-info">
							<h6>Get Support</h6>
							<h4><a href="tel:+1 604-283-7610">+1 604-283-7610</a></h4>
						</div>
						</div>
					</div>
					</div>
					<div className="col header-menu-column">
					<div className="header-menu d-none d-xl-block">
						<nav>
						<div className="ltn__main-menu go-top">
							<ul>
							<li ><Link to="/">Home</Link>

							</li>
								<li><Link to="/service">Our Services</Link></li>

							<li ><Link to="/listings">Properties</Link>

							</li>
							<li ><Link to="/all-news">News</Link>

							</li>
							<li ><Link to="/team">Our Team</Link>

							</li>
							<li><Link to="/contact">Contact</Link></li>
							<li><Link to="/faq">FAQ</Link></li>
							</ul>
						</div>
						</nav>
					</div>
					</div>
					<div className="col ltn__header-options ltn__header-options-2 mb-sm-20">

					<div className="ltn__drop-menu user-menu">
						<ul>
						<li>
							<Link to="#"><i className="icon-user" /></Link>
							<ul className="go-top">
							<li><Link to="/login">Sign in</Link></li>
							<li><Link to="/register">Register</Link></li>

							</ul>
						</li>
						</ul>
					</div>

					{/* Mobile Menu Button */}
					<div className="mobile-menu-toggle d-xl-none">
						<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
						<svg viewBox="0 0 800 600">
							<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
							<path d="M300,320 L540,320" id="middle" />
							<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
						</svg>
						</a>
					</div>
					</div>
				</div>
				</div>
			</div>
			</header>
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
						<Link to="/"><img src={publicUrl+"assets/img/Moovmynt - Revised Large Logo - White Background.png"} alt="Logo" /></Link>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>

					<div className="ltn__utilize-menu">
					<ul>
						<li><Link to="/">Home</Link>

						</li>
						<li><Link to="/service">Our Services</Link> </li>
						<li><Link to="/listings">Properties</Link>

						</li>


						<li ><Link to="/all-news">News</Link>

						</li>
							<li ><Link to="/team">Our Team</Link> </li>

						<li><Link to="/contact">Contact</Link></li>
							<li><Link to="/faq">FAQ</Link></li>
					</ul>
					</div>
					<div className="ltn__utilize-buttons ltn__utilize-buttons-2">
				<ul>
						<li>
							<Link to="#"><i className="icon-user" /></Link>
							<ul className="go-top">
							<li><Link to="/login">Sign in</Link></li>
							<li><Link to="/register">Register</Link></li>

							</ul>
						</li>
						</ul>
					</div>
					<div className="ltn__social-media-2">
					<ul>
						<li><a href="https://www.facebook.com/Moovmynt-101542262090927" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="https://x.com/i/flow/login?redirect_after_login=%2FMoovmynt" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="https://www.youtube.com/channel/UCjiAgIeiBd6oDX0GzF-6ZBQ" title="Youtube"><i className="fab fa-youtube" /></a></li>
						<li><a href="https://www.instagram.com/moovmynt/" title="Instagram"><i className="fab fa-instagram" /></a></li>
					</ul>
					</div>
				</div>
			</div>



		</div>
        )
    }
}


export default Navbar